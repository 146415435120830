import { defineStore } from "pinia";

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    maintenance: false,
  }),
  getters: {
    getMaintenanceMode(state): boolean {
      return state.maintenance;
    },
  },
  actions: {
    setMaintenanceMode(value: boolean) {
      this.maintenance = value;
    },
  },
});
