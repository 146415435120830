import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _directive_money = _resolveDirective("money")!

  return _withDirectives((_openBlock(), _createBlock(_component_v_text_field, {
    label: _ctx.label,
    type: "text",
    "hide-details": !_ctx.required,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => _ctx.$emit('update:value', value))
  }, null, 8, ["label", "hide-details"])), [
    [_directive_money, _ctx.money]
  ])
}