<template>
  <li class="sidemenu-item" :style="itemStyle">
    <router-link
      :to="route.params ? { name: route.name, params: route.params } : route"
      class="sidemenu-link"
    >
      {{ label }}
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

import { useWhiteLabelStore } from "@/stores/whiteLabel.store";

export default defineComponent({
  name: "SidebarSubItem",
  props: {
    route: {
      type: Object,
      default() {
        return { name: "", params: null };
      },
      required: true,
    },
    label: {
      type: String,
      default: "Listar",
      required: true,
    },
  },
  setup(props) {
    const whiteLabelStore = useWhiteLabelStore();

    const primaryColor = computed(
      () => whiteLabelStore.domain.primary_color || "#3f51b5"
    );
    const secondaryColor = computed(
      () => whiteLabelStore.domain.secondary_color || "#ee368c"
    );

    const itemStyle = computed(() => ({
      "--background-color": secondaryColor.value,
      "--color": primaryColor.value,
      "--hover-background-color": primaryColor.value,
      "--hover-color": "#FFF",
    }));

    return {
      itemStyle,
    };
  },
});
</script>

<style>
.sidemenu-item:hover {
  border-radius: 5px;
  background-color: var(--hover-background-color) !important;
}

.sidemenu-link:hover {
  color: var(--hover-color) !important;
}

.sidemenu-link.active {
  background-color: var(--hover-background-color) !important;
}
</style>
