<template>
  <v-alert
    :class="`alert d-flex justify-space-between align-items-center`"
    closable
    @click:close="closeAlert()"
    :type="type"
  >
    {{ message }}
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export type Alert = {
  success: "success";
};

export default defineComponent({
  name: "AlertComponent",
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: String as () => "success" | "error" | "warning" | "info" | any,
      default: "success",
      required: true,
    },
  },
  methods: {
    closeAlert() {
      this.$emit("close-alert", true);
    },
  },
  mounted() {
    setTimeout(() => {
      this.closeAlert();
    }, 3500);
  },
});
</script>
