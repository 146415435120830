import { defineStore } from "pinia";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    token: "" as string,
    user: {} as object | string,
  }),

  getters: {
    getToken(state): string {
      return localStorage.getItem("token") ?? state.token;
    },

    getUser(state): object | string {
      return JSON.parse(localStorage.getItem("user") ?? "") ?? state.user;
    },
  },

  actions: {
    setToken(token: string) {
      localStorage.setItem("token", token);
      this.token = token;
    },

    setUser(user: object) {
      localStorage.setItem("user", JSON.stringify(user));
      this.user = user;
    },

    setLogout() {
      this.setToken("");
      this.setUser({});
    },
  },
});
