<template>
  <v-snackbar v-model="snackbarStore.isVisible" color="success">
    {{ snackbarStore.message }}
    <template v-slot:actions>
      <v-btn
        class="text-capitalize"
        variant="text"
        @click="snackbarStore.isVisible = false"
      >
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useSnackbarStore } from "@/stores/snackbar.store";

export default defineComponent({
  name: "SuccessSnackbar",
  setup() {
    const snackbarStore = useSnackbarStore();

    return {
      snackbarStore,
    };
  },
});
</script>
