import { WhiteLabelService } from "@/services";

import { useWhiteLabelStore } from "@/stores/whiteLabel.store";

import { DomainUtils } from "@/utils/domain.utils";

import { isLoading } from "@/shared/base.service";

export function useWhiteLabel() {
  const whiteLabelStore = useWhiteLabelStore();

  async function getWhiteLabelData() {
    try {
      const domainInfo = DomainUtils().getDomainAndSubdomain();

      const response = await WhiteLabelService.GetWhiteLabelData(
        domainInfo.domain
      );

      if (response) {
        whiteLabelStore.setDomainData(response);
        DomainUtils().setWhiteLabelProps();

        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    getWhiteLabelData,
  };
}
