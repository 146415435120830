import BaseService from "@/shared/base.service";

import type { Company } from "@/types/models/company";

export class CompanyService {
  static async GetAllCompanies(): Promise<Company[] | []> {
    try {
      const response = await BaseService.get<Company[]>("empresa/empresas");
      return response ?? [];
    } catch (error) {
      console.error("Error in GetAllCompanies:", error);
      return [];
    }
  }
}
