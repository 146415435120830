import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center pa-4" }
const _hoisted_2 = { class: "pingo-form-group without-icon pl-5 pr-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertComponent = _resolveComponent("AlertComponent")!
  const _component_MonetaryInput = _resolveComponent("MonetaryInput")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.alert.show)
      ? (_openBlock(), _createBlock(_component_AlertComponent, {
          key: 0,
          style: {"position":"fixed","right":"2%","top":"2%","z-index":"999"},
          message: _ctx.alert.message,
          type: _ctx.alert.type,
          onCloseAlert: _cache[0] || (_cache[0] = (e) => (_ctx.alert.show = !e))
        }, null, 8, ["message", "type"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.isDialogOpen,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isDialogOpen) = $event)),
        width: "auto"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            "max-width": "450",
            "prepend-icon": "mdi-update",
            text: "Insira o valor que deseja transferir de saldo.",
            title: "Adicionar Saldo"
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "ms-auto border",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeDialog()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Fechar")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                "prepend-icon": "mdi-check-circle",
                class: "mr-5 border",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.save(_ctx.data)))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_icon, { color: "success" })
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Salvar ")
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_MonetaryInput, {
                  label: 'Saldo',
                  modelValue: _ctx.amount,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.amount) = $event)),
                  "onUpdate:value": _cache[2] || (_cache[2] = (value) => _ctx.updateInitValue(value))
                }, null, 8, ["modelValue"])
              ]),
              _createVNode(_component_v_divider)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}