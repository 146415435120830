import BaseService from "@/shared/base.service";

import type { FeeResponse } from "@/types/responses/fee";

export class FeeService {
  static async GetAllFees(
    init_date?: string,
    finish_date?: string,
    table_id?: number,
    type?: "detalhado" | "consolidado" | "mesarios"
  ): Promise<FeeResponse | null> {
    try {
      const params = {
        init_date,
        finish_date,
        table_id,
        type,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<FeeResponse>(
        `financial/fees`,
        queryParams
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllFees:", error);
      return null;
    }
  }
}
