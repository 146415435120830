import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", {
    class: "sidemenu-item",
    style: _normalizeStyle(_ctx.itemStyle)
  }, [
    _createVNode(_component_router_link, {
      to: _ctx.route.params ? { name: _ctx.route.name, params: _ctx.route.params } : _ctx.route,
      class: "sidemenu-link"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ], 4))
}