// src/i18n.js
import { createI18n } from "vue-i18n";
import { pt, en } from "vuetify/locale";

const messages = {
  en: {
    $vuetify: {
      ...en,
      dataIterator: {
        rowsPerPageText: "Items per page:",
        pageText: "{0}-{1} of {2}",
      },
    },
  },
  pt: {
    $vuetify: {
      ...pt,
      dataIterator: {
        rowsPerPageText: "Itens por página:",
        pageText: "{0}-{1} de {2}",
      },
    },
  },
};

const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: "pt",
  fallbackLocale: "en",
  messages,
});

export default i18n;
