<template>
  <v-autocomplete
    label="Selecione um Jogador"
    :loading="isLoadingJogadoresInfo"
    :item-value="'player.id'"
    :item-title="'name'"
    :items="users"
    :hide-details="!required"
    :no-data-text="
      isLoadingJogadoresInfo == true ? 'Carregando...' : 'Não há nenhum jogador'
    "
    @update:menu="(value) => (isSelectMenuOpen = value)"
    @update:model-value="(value) => $emit('update:value', value)"
  >
    <template v-slot:item="{ props, item }">
      <v-list-item
        v-bind="props"
        :subtitle="`ID: ${item.raw.player.id ?? 'Desconhecido'}`"
      ></v-list-item>
    </template>

    <template v-slot:no-data>
      <div class="text-center">
        {{ isLoadingJogadoresInfo ? "Carregando..." : "Não há nenhum jogador" }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useUser } from "@/composables/useUser";

export default defineComponent({
  name: "SelectJogador",
  props: {
    label: {
      type: String,
      default: "Selecione um Jogador",
    },
    noDataLabel: {
      type: String,
      default: "Não há nenhum jogador",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value"],
  setup() {
    const { loggedUser, users, getAllUsers } = useUser();

    const isSelectMenuOpen = ref(false);
    const isLoadingJogadoresInfo = ref(false);

    const fetchJogadoresData = async () => {
      try {
        isLoadingJogadoresInfo.value = true;

        getAllUsers(loggedUser.value.type, "jogador").then(() => {
          isLoadingJogadoresInfo.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de jogadores:", error);
      }
    };

    watch(
      () => isSelectMenuOpen.value,
      () => {
        fetchJogadoresData();
      },
      {
        once: true,
      }
    );

    return {
      isSelectMenuOpen,
      isLoadingJogadoresInfo,
      users,
    };
  },
});
</script>

<style>
.v-select .v-select__selection-text {
  overflow: hidden !important;
}
</style>
