<template>
  <div class="not-found text-center">
    <v-img
      src="@/assets/img/not-found.gif"
      class="d-block mb-25"
      alt="não encontrado"
    />
    <h4 class="mb-15">Ops!! A página que você está procurando não existe!</h4>
    <p class="ms-auto me-auto">
      A página que você está procurando pode ter sido removida, teve seu nome
      alterado ou está temporariamente indisponível.
    </p>
    <div class="mt-10">
      <router-link class="success" to="/">Voltar para o Dashboard</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorPage",
  components: {},
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.not-found {
  padding: {
    top: 100px;
    bottom: 100px;
  }
  p {
    max-width: 540px;
  }

  button,
  a {
    padding: 14px 35px !important;
    border-radius: 30px !important;
    display: inline-block;

    &.success {
      background-color: var(--heraBlueColor);
      color: var(--whiteColor);
      display: inline-block;
    }
    font: {
      weight: 600 !important;
      size: 14px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .not-found {
    padding: {
      top: 50px;
      bottom: 50px;
    }
    p {
      max-width: 100%;
    }
  }
}
</style>
