<template>
  <v-autocomplete
    label="Selecione uma Empresa"
    :loading="isLoadingCompaniesInfo"
    :item-value="'id'"
    :item-title="'nome'"
    :items="companies"
    v-model="company"
    :readonly="readonly"
    :hide-details="!required"
    :no-data-text="
      isLoadingCompaniesInfo == true ? 'Carregando...' : 'Não há nenhuma empresa'
    "
    @update:menu="(value) => (isSelectMenuOpen = value)"
    @update:model-value="(value) => $emit('update:value', value)"
  >
    <template v-slot:no-data>
      <div class="text-center">
        {{ isLoadingCompaniesInfo ? "Carregando..." : "Não há nenhuma empresa" }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref, watch } from "vue";

import { useAuthStore } from "@/stores/auth.store";

import { useCompany } from "@/composables/useCompany";

export default defineComponent({
  name: "SelectCompany",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    loadAtMounted: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const authStore = useAuthStore();

    const { company, companies, getAllCompanies } = useCompany();

    const user: Ref = ref(authStore.getUser);

    const isSelectMenuOpen = ref(false);
    const isLoadingCompaniesInfo = ref(false);

    const fetchCompaniesData = async () => {
      try {
        isLoadingCompaniesInfo.value = true;

        await getAllCompanies().then(() => {
          isLoadingCompaniesInfo.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de empresas:", error);
      }
    };

    watch(
      () => isSelectMenuOpen.value,
      () => {
        if (props.loadAtMounted == false) fetchCompaniesData();
      },
      {
        once: true,
      }
    );

    onMounted(async () => {
      if (props.loadAtMounted == true) await fetchCompaniesData();

      if (props.readonly == true) {
        const producerCompany = companies.value.find(
          (item) => item.empresa_id == user.value.empresa_id
        );

        companies.value = companies.value.filter(
          (item) => item.empresa_id != user.value.empresa_id
        );

        company.value = producerCompany;
        emit("update:value", producerCompany?.empresa_id);
      }
    });

    return {
      isSelectMenuOpen,
      isLoadingCompaniesInfo,
      company,
      companies,
    };
  },
});
</script>
