import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pingo-form-group mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_DashboardFilters = _resolveComponent("DashboardFilters")!
  const _component_AdminStats = _resolveComponent("AdminStats")!
  const _component_CounterAcountStats = _resolveComponent("CounterAcountStats")!
  const _component_AccountFinancialsStats = _resolveComponent("AccountFinancialsStats")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Dashboard",
      mainTitle: "Dashboard"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            lg: "12",
            xl: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DashboardFilters),
              (_ctx.user.type == 'admin')
                ? (_openBlock(), _createBlock(_component_AdminStats, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(_component_CounterAcountStats),
              _createVNode(_component_AccountFinancialsStats)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}