<template>
  <AlertComponent
    v-if="alert.show"
    style="position: fixed; right: 2%; bottom: 0px; z-index: 1"
    :message="alert.message"
    :type="alert.type"
    @close-alert="(e) => (alert.show = !e)"
  />

  <AddBalance
    :open="addBalanceModal"
    :data="addBalanceTo ?? 0"
    @close-dialog="clickAddBalanceModal()"
  />

  <PageTitle pageTitle="Contas" mainTitle="Contas" />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-0">Contas</h5>

      <v-btn
        v-if="user.type == 'jogador'"
        :to="{
          name: 'NewAccountsPage',
        }"
        class="default-btn"
      >
        <i class="ri-add-fill"></i>
        Adicionar
      </v-btn>
    </div>

    <v-row class="d-flex flex-row align-center">
      <v-col cols="12" lg="3">
        <div class="pingo-form-group without-icon mb-0">
          <SelectPlayerId
            v-model="filters.player"
            :required="false"
            @update:value="updatePlayerId($event)"
          />
        </div>
      </v-col>

      <v-col cols="12" lg="3">
        <div class="pingo-form-group without-icon mb-0">
          <!-- <TextLabel :title="'Documento'" /> -->
          <TextInput
            :label="'Documento'"
            :required="false"
            v-model="filters.document"
          />
        </div>
      </v-col>

      <v-col cols="12" lg="3">
        <div class="pingo-form-group without-icon mb-0">
          <MonetaryInput
            :label="'Digite o Valor Inicial aqui'"
            :required="false"
            @update:value="(value: string) => filters.init_value = value"
          />
        </div>
      </v-col>

      <v-col cols="12" lg="3">
        <div class="pingo-form-group without-icon mb-0">
          <MonetaryInput
            :label="'Digite o Valor Final aqui'"
            :required="false"
            @update:value="(value: string) => filters.finish_value = value"
          />
        </div>
      </v-col>

      <v-col cols="12" lg="2">
        <div class="pingo-form-group without-icon mb-0">
          <!-- <TextLabel :title="'Saldo'" /> -->
          <v-select
            label="Saldo"
            :items="['Zerado', 'Positivo']"
            hide-details
            v-model="filters.balance"
          />
        </div>
      </v-col>

      <v-col cols="12" lg="2">
        <div class="pingo-form-group without-icon mb-0">
          <!-- <TextLabel :title="'Status'" /> -->
          <v-select
            label="Status"
            :items="[
              {
                id: 1,
                title: 'Aguardando Saldo',
              },
              {
                id: 2,
                title: 'Aguardando Aporte',
              },
              {
                id: 3,
                title: 'Aguardando Recebimento',
              },
              {
                id: 4,
                title: 'Aguardando Saque',
              },
              {
                id: 5,
                title: 'Aguardando Aprovação',
              },
              {
                id: 6,
                title: 'Aguardando Informações',
              },
              {
                id: 7,
                title: 'Existe Erro no Cadastro',
              },
              {
                id: 9,
                title: 'Reprovado',
              },
              {
                id: 'Inativo',
                title: 'Inativo',
              },
            ]"
            item-value="id"
            v-model="filters.status"
            hide-details
          />
        </div>
      </v-col>

      <v-col cols="12" lg="2">
        <div class="pingo-form-group without-icon">
          <v-btn
            class="w-100 lg-mt-10"
            @click="filter()"
            size="large"
            variant="outlined"
            style="margin-top: 25px"
          >
            Filtrar
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-data-table
      v-if="isLoadingAccounts"
      :headers="[]"
      :items="accounts"
      :loading="isLoadingAccounts"
      :items-per-page="9"
    >
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
      </template>
    </v-data-table>

    <Table
      v-else
      :columns="[
        'ID',
        'Empresa',
        'Nome',
        'Documento',
        'Proprietário',
        'Email',
        'Saldo',
        'Status',
        'PIX',
        'Ações',
      ]"
      :data="accounts"
    >
      <template v-slot:data>
        <tr v-for="account in accounts.slice(0, 9)" :key="account.id">
          <td class="text-start">
            <router-link
              class="fw-semibold main-color"
              :to="{
                name: 'AccountsPageDetails',
                params: { id: account.id },
              }"
            >
              #{{ account.id }}
            </router-link>
          </td>

          <td>
            <div class="title">{{ account.empresa }}</div>
          </td>

          <td>
            <div class="title">{{ account.proprietario ?? "-" }}</div>
          </td>

          <td>
            <div class="title">
              {{
                account.documento?.replace(
                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3-$4"
                ) ?? ""
              }}
            </div>
          </td>

          <td>
            <div class="title">{{ account?.nome ?? "-" }}</div>
          </td>

          <td>
            <div class="title">{{ account.email ?? "-" }}</div>
          </td>

          <td>
            <div class="title">
              {{
                Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(account.balance)
              }}
            </div>
          </td>

          <td>
            <span
              v-if="account.status_account == 'Ativo'"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              {{ account.status_account }}
            </span>

            <span
              v-if="account.status_account == 'Bloqueado'"
              class="text-capitalize badge rounded-pill text-soft-warning"
            >
              {{ account.accountStatus?.description }}
            </span>

            <span
              v-if="account.status_account == 'Inativo'"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              {{ account.status_account }}
            </span>
          </td>

          <td>
            <span
              v-if="account.pix_active == 0"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Inativo
            </span>

            <span
              v-if="account.pix_active == 1"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Ativo
            </span>
          </td>

          <td>
            <v-menu>
              <template v-slot:activator="{ props }">
                <button
                  :disabled="isLoadingAccountsAction"
                  type="button"
                  v-bind="props"
                  class="position-relative dot-btn p-0 bg-transparent border-none"
                >
                  <i
                    v-if="isLoadingAccountsAction == false"
                    class="ri-more-fill"
                  ></i>
                  <v-progress-circular
                    :size="14"
                    :width="1"
                    v-else
                    indeterminate
                  ></v-progress-circular>
                </button>
              </template>

              <v-list class="dotMenu monthMenu" style="width: 250px">
                <button
                  type="button"
                  class="bg-transparent w-100 border-none"
                  v-if="
                    account.status_account === 'inativo' &&
                    user.type !== 'produtor'
                  "
                  @click="activateAccount(account.id)"
                >
                  Ativar
                </button>

                <button
                  type="button"
                  class="bg-transparent w-100 border-none"
                  v-if="
                    (account.status_account !== 'Bloqueado' &&
                      !(user.type == 'produtor') &&
                      account.account_status_id <= 1) ||
                    user.type == 'admin'
                  "
                  @click="updateBalance(account.id)"
                >
                  Atualizar Saldo
                </button>

                <button
                  type="button"
                  class="bg-transparent w-100 border-none"
                  v-if="
                    !(user.type == 'produtor') &&
                    account.balance > 1 &&
                    !account.block_contribution &&
                    account.account_type_id == 1 &&
                    account.account_status_id == 2
                  "
                  @click="sendBrCode(account.id)"
                >
                  Enviar Aporte
                </button>

                <button
                  type="button"
                  class="bg-transparent w-100 border-none"
                  v-if="
                    !(user.type == 'produtor') &&
                    account.qtd_pix <= 3 &&
                    account.account_status_id <= 4
                  "
                  @click="createPix(account.id)"
                >
                  Ativar Pix
                </button>

                <button
                  type="button"
                  class="bg-transparent w-100 border-none"
                  v-if="user.type == 'admin' && account.balance > 0"
                  @click="inactivateAccount(account.id)"
                >
                  Forçar Split
                </button>

                <button
                  type="button"
                  class="bg-transparent w-100 border-none"
                  v-if="
                    !(user.type == 'produtor') &&
                    account.balance > 0 &&
                    account.account_status_id == 2 &&
                    account.has_return_balance == false &&
                    account.account_type_id == 1
                  "
                  @click="returnBalance(account.id)"
                >
                  Devolução
                </button>

                <!-- <button
                  type="button"
                  class="bg-transparent w-100 border-none"
                  v-if="user.type != 'produtor' && account.account_status_id == 3"
                >
                  Valor do Recebimento
                </button> -->

                <!-- Valor do Recebimento -->
                <v-dialog
                  v-if="
                    user.type != 'produtor' && account.account_status_id == 3
                  "
                  v-model="receiptValueDialog"
                  max-width="680"
                  scrollable
                >
                  <template v-slot:activator="{ props: activatorProps }">
                    <button
                      v-bind="activatorProps"
                      type="button"
                      class="bg-transparent w-100 border-none"
                    >
                      Taxa Zero
                    </button>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-form @submit.prevent="handleReceiptValue(account.id)">
                      <v-card class="py-0" title="Taxa Zero">
                        <v-divider></v-divider>

                        <v-card-text class="px-4">
                          <v-row>
                            <v-col cols="12">
                              <div class="pingo-form-group without-icon mb-2">
                                <label
                                  class="d-block mb-12 fw-semibold gray-color"
                                >
                                  Valor de recebimento <span>*</span>
                                </label>

                                <MonetaryInput
                                  :label="'Digite o valor aqui'"
                                  @update:value="(value: string) => newReceiptValueForm.amount = 
                                  value.replace('R$', '').replace(/\./g, '').replace(',', '.').trim()
                                "
                                  :error-messages="
                                    v$receipt.amount.$errors
                                      ? v$receipt.amount.$errors[0]?.$message
                                      : undefined
                                  "
                                />
                              </div>

                              <v-checkbox
                                class="gray-color"
                                hide-details
                                color="info"
                                label="Taxa Zero"
                                v-model="newReceiptValueForm.rate_zero"
                              >
                              </v-checkbox>

                              <div v-if="newReceiptValueForm.rate_zero == true">
                                <div class="pingo-form-group without-icon mb-2">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Tipo <span>*</span>
                                  </label>

                                  <v-select
                                    label="Selecione um tipo"
                                    :items="[
                                      {
                                        name: 'Sem bônus',
                                        value: 'bonus',
                                      },
                                      {
                                        name: 'Depósito administrativo',
                                        value: 'deposito_adm',
                                      },
                                    ]"
                                    persistent-hint
                                    item-title="name"
                                    item-value="value"
                                    v-model="newReceiptValueForm.type"
                                  ></v-select>
                                </div>

                                <div class="pingo-form-group without-icon mb-2">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Login <span>*</span>
                                  </label>

                                  <TextInput
                                    :label="'Digite o login aqui'"
                                    v-model="newReceiptValueForm.login"
                                    :error-messages="
                                      v$receipt.login.$errors
                                        ? v$receipt.login.$errors[0]?.$message
                                        : undefined
                                    "
                                  />
                                </div>

                                <div class="pingo-form-group without-icon mb-2">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Senha <span>*</span>
                                  </label>

                                  <TextInput
                                    :label="'Digite a senha aqui'"
                                    v-model="newReceiptValueForm.password"
                                    :error-messages="
                                      v$receipt.password.$errors
                                        ? v$receipt.password.$errors[0]
                                            ?.$message
                                        : undefined
                                    "
                                  />
                                </div>

                                <div class="pingo-form-group without-icon mb-2">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Descrição <span>*</span>
                                  </label>

                                  <TextareaInput
                                    :label="'Digite a descrição aqui'"
                                    v-model="newReceiptValueForm.description"
                                    :error-messages="
                                      v$receipt.description.$errors
                                        ? v$receipt.description.$errors[0]
                                            ?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            class="text-capitalize"
                            @click="isActive.value = false"
                          >
                            Fechar
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-btn
                            class="text-capitalize"
                            color="green-darken-3"
                            variant="tonal"
                            type="submit"
                          >
                            Confirmar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </template>
                </v-dialog>

                <button
                  type="button"
                  class="bg-transparent w-100 border-none"
                  @click="
                    () => {
                      clickAddBalanceModal();
                      addBalanceTo = account.id;
                    }
                  "
                >
                  Adicionar Saldo
                </button>

                <button
                  v-if="account.id_bmp == null"
                  type="button"
                  class="bg-transparent w-100 border-none"
                  @click="
                    () => {
                      handleAproveBmp(account.id);
                    }
                  "
                >
                  Pedir nova aprovação
                </button>

                <!-- Pagar QR Code -->
                <v-dialog
                  v-if="account.status_account !== 'Bloqueado'"
                  v-model="qrCodeDialog"
                  max-width="680"
                  scrollable
                >
                  <template v-slot:activator="{ props: activatorProps }">
                    <button
                      v-bind="activatorProps"
                      type="button"
                      class="bg-transparent w-100 border-none"
                    >
                      Pagar QR Code
                    </button>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-form @submit.prevent="handlePayQrCode(account.id)">
                      <v-card class="py-0" title="Pagar QR Code">
                        <v-divider></v-divider>

                        <v-card-text class="px-4">
                          <v-row>
                            <v-col cols="12">
                              <div class="pingo-form-group without-icon mb-0">
                                <label
                                  class="d-block mb-12 fw-semibold gray-color"
                                >
                                  QR Code <span>*</span>
                                </label>

                                <TextareaInput
                                  :label="'Digite o qr code aqui'"
                                  v-model="newQrCodeForm.qr_code"
                                  :error-messages="
                                    v$.qr_code.$errors
                                      ? v$.qr_code.$errors[0]?.$message
                                      : undefined
                                  "
                                />
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            class="text-capitalize"
                            @click="isActive.value = false"
                          >
                            Fechar
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-btn
                            class="text-capitalize"
                            color="green-darken-3"
                            variant="tonal"
                            type="submit"
                          >
                            Confirmar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </template>
                </v-dialog>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </Table>

    <ItemsPerPage @change-page="(page: Pagination) => chagePage(page)" />
  </v-card>

  <LoadingDialog />
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue";

import useVuelidate from "@vuelidate/core";

import { useLoadingDialog } from "@/stores/loading.store";

import { useAuthStore } from "@/stores/auth.store";

import { useAccounts } from "@/composables/useAccounts";
import { usePlayer } from "@/composables/usePlayer";

import AlertComponent from "@/components/Alerts/AlertComponent.vue";

import PageTitle from "@/components/Common/PageTitle.vue";
import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";

import Table from "@/components/Tables/Table.vue";

import AddBalance from "@/pages/Accounts/Common/AddBalance.vue";

import SelectPlayerId from "@/components/Forms/Select/SelectPlayerId.vue";

import MonetaryInput from "@/components/Forms/AdvancedElements/MonetaryInput.vue";
import TextareaInput from "@/components/Forms/BasicElements/TextareaInput.vue";
// import TextLabel from "@/components/Forms/BasicElements/TextLabel.vue";
import TextInput from "@/components/Forms/BasicElements/TextInput.vue";

import LoadingDialog from "@/components/Dialog/LoadingDialog.vue";

import type { Accounts } from "@/types/models/accounts";
import type { Pagination } from "@/types/models/pagination";

export default defineComponent({
  name: "AccountsPage",
  components: {
    PageTitle,
    SelectPlayerId,
    MonetaryInput,
    TextareaInput,
    // TextLabel,
    TextInput,
    Table,
    AlertComponent,
    ItemsPerPage,
    AddBalance,
    LoadingDialog,
  },
  setup() {
    const loadingStore = useLoadingDialog();

    const {
      newReceiptValueForm,
      newReceiptValueFormRules,
      newQrCodeFormRules,
      accountReceive,
      newQrCodeForm,
      payQrCode,
      aproveBmp,
    } = useAccounts();

    const accounts = ref<Accounts[]>([]);
    const addBalanceTo = ref<number>();
    const filters = ref<any>({
      player: null,
      document: null,
      init_value: null,
      finish_value: null,
      balance: null,
      status: null,
      page: 1,
    });

    const alert = ref({
      show: false,
      type: "",
      message: "",
    });

    const isLoadingAccountsAction = ref(false);
    const isLoadingAccounts = ref(false);

    const qrCodeDialog = ref(false);
    const receiptValueDialog = ref(false);
    const addBalanceModal = ref<boolean>(false);

    const user: Ref = ref(useAuthStore().getUser);

    const listAccounts = async (params = null) => {
      isLoadingAccounts.value = !isLoadingAccounts.value;

      await useAccounts()
        .listAccounts(params)
        .then(({ value }) => {
          value?.map((item: any) => {
            accounts.value.push({
              id: item.id,
              empresa: item.empresa?.nome ?? "Empresa não informada",
              nome: item.user,
              documento: item.accountable_cpf,
              proprietario: item.accountable,
              email: item.email,
              id_bmp: item.id_bmp,
              balance: item.balance,
              status_account: item.status_account,
              pix: item.player_pix_key,
              pix_active: item.pix_active,
              account_status_id: item.account_status_id,
              accountStatus: {
                id: 0,
                description: item.accountStatus.description,
                created_at: "",
                updated_at: "",
                empresa_id: 0,
              },
              block_contribution: item.block_contribution,
              account_type_id: item.account_type_id,
              has_return_balance: item.hasReturnBalance,
              qtd_pix: item.qtd_pix,
              acoes: "",
            });
          });
        });

      isLoadingAccounts.value = !isLoadingAccounts.value;
    };

    const updatePlayerId = (value: number) => (filters.value.player = value);

    const updateBalance = async (accountId: number) => {
      isLoadingAccountsAction.value = !isLoadingAccountsAction.value;

      await useAccounts()
        .updateBalance(accountId)
        .then(() => {
          alert.value = {
            message: "Saldo atualizado com sucesso",
            type: "success",
            show: true,
          };

          listAccounts();
        })
        .catch(() => {
          alert.value = {
            message: "Ocorreu um erro ao atualizar o saldo",
            type: "error",
            show: true,
          };
        });

      isLoadingAccountsAction.value = !isLoadingAccountsAction.value;
    };

    const sendBrCode = async (accountId: number) => {
      await useAccounts()
        .sendBrCode(accountId)
        .then((data) => {
          if (!data) {
            alert.value = {
              message: "Ocorreu um erro",
              type: "error",
              show: true,
            };
          } else {
            alert.value = {
              message: "Gerado com sucesso",
              type: "success",
              show: true,
            };
          }
        })
        .catch(() => {
          alert.value = {
            message: "Ocorreu um erro",
            type: "error",
            show: true,
          };
        });
    };

    const inactivateAccount = async (accountId: number) => {
      loadingStore.setLoading("Registrando...");

      const response = await useAccounts().inactivateAccount(accountId);

      if (!response) {
        alert.value = {
          message: "Ocorreu um erro inativar a conta.",
          type: "error",
          show: true,
        };
      }

      loadingStore.setLoadingOff();
    };

    const activateAccount = async (accountId: number) => {
      await useAccounts()
        .activateAccount(accountId)
        .then((data) => {
          if (!data) {
            alert.value = {
              message: "Ocorreu um erro ativar a conta",
              type: "error",
              show: true,
            };
          } else {
            alert.value = {
              message: "Conta ativada",
              type: "success",
              show: true,
            };
          }
        })
        .catch(() => {
          alert.value = {
            message: "Ocorreu um erro ativar a conta",
            type: "error",
            show: true,
          };
        });
    };

    const createPix = async (accountId: number) => {
      await useAccounts()
        .createPix(accountId)
        .then((data) => {
          if (!data) {
            alert.value = {
              message: "Ocorreu um erro ao criar o Pix",
              type: "error",
              show: true,
            };
          } else {
            alert.value = {
              message: "Pix criado",
              type: "success",
              show: true,
            };
          }
        })
        .catch(() => {
          alert.value = {
            message: "Ocorreu um erro ao criar o Pix",
            type: "error",
            show: true,
          };
        });
    };

    const returnBalance = async (accountId: number) => {
      await useAccounts()
        .returnBalance(accountId)
        .then((data) => {
          if (!data) {
            alert.value = {
              message: "Ocorreu um erro ao fazer a devolução",
              type: "error",
              show: true,
            };
          } else {
            alert.value = {
              message: "Devolução realizada",
              type: "success",
              show: true,
            };
          }
        })
        .catch(() => {
          alert.value = {
            message: "Ocorreu um erro ao fazer a devolução",
            type: "error",
            show: true,
          };
        });
    };

    const filter = async () => {
      let mountFilter: any = {};

      if (filters.value.player) {
        mountFilter.player_id = filters.value.player;
      }

      if (filters.value.document) {
        if (filters.value.document) {
          mountFilter.accountable_cpf = filters.value.document.replace(
            /[.-]/g,
            ""
          );
        }
      }

      if (filters.value.init_value) {
        let initValue = filters.value.init_value
          .replace("R$", "")
          .replace(/\./g, "")
          .replace(",", ".")
          .replace(".", ",")
          .trim();

        if (initValue !== "0,00") {
          mountFilter.init_value = initValue;
        } else {
          mountFilter.init_value = null;
        }
      }

      if (filters.value.finish_value) {
        let finishValue = filters.value.finish_value
          .replace("R$", "")
          .replace(/\./g, "")
          .replace(",", ".")
          .replace(".", ",")
          .trim();

        if (finishValue !== "0,00") {
          mountFilter.finish_value = finishValue;
        } else {
          mountFilter.finish_value = null;
        }
      }

      if (filters.value.balance) {
        mountFilter.saldo =
          filters.value.balance == "Positivo" ? "positivo" : "zerado";
      }

      if (filters.value.status) {
        mountFilter.status_conta = filters.value.status;
      }

      if (filters.value.page) {
        mountFilter.page = filters.value.page;
      }

      accounts.value = [];

      await listAccounts(mountFilter);
    };

    const chagePage = async (pagination: Pagination) => {
      filters.value.page = pagination.page;
      await filter();
    };

    const clickAddBalanceModal = () => {
      addBalanceModal.value = !addBalanceModal.value;
    };

    const handleAproveBmp = async (account_id: number) => {
      loadingStore.setLoading("Solicitando aprovação...");

      const response = await aproveBmp(account_id);

      if (!response) {
        alert.value = {
          message: "Ocorreu um erro ao solicitar a aprovação.",
          type: "error",
          show: true,
        };
      }

      loadingStore.setLoadingOff();
    };

    const handlePayQrCode = async (account_id: number) => {
      newQrCodeForm.value.account_id = account_id;

      const result = await v$.value.$validate();

      if (!result) return;

      loadingStore.setLoading("Realizando pagamento...");

      const response = await payQrCode(newQrCodeForm.value);

      if (!response) {
        alert.value = {
          message: "Ocorreu um erro ao pagar o QR Code.",
          type: "error",
          show: true,
        };
      }

      loadingStore.setLoadingOff();
    };

    const handleReceiptValue = async (account_id: number) => {
      newReceiptValueForm.value.account_id = account_id;

      const result = await v$receipt.value.$validate();

      if (!result) return;

      loadingStore.setLoading("Registrando solicitação...");

      const response = await accountReceive(newReceiptValueForm.value);

      if (!response) {
        alert.value = {
          message: "Ocorreu um erro ao pagar o solicitar valor de recebimento.",
          type: "error",
          show: true,
        };
      }

      loadingStore.setLoadingOff();
    };

    listAccounts();

    const v$ = useVuelidate(newQrCodeFormRules, newQrCodeForm);
    const v$receipt = useVuelidate(
      newReceiptValueFormRules,
      newReceiptValueForm,
      {
        $scope: false,
      }
    );

    return {
      v$,
      v$receipt,
      accounts,
      alert,
      user,
      filters,
      addBalanceModal,
      addBalanceTo,
      clickAddBalanceModal,
      updatePlayerId,
      updateBalance,
      filter,
      chagePage,
      sendBrCode,
      createPix,
      inactivateAccount,
      returnBalance,
      activateAccount,
      isLoadingAccountsAction,
      isLoadingAccounts,
      qrCodeDialog,
      newQrCodeForm,
      handleAproveBmp,
      handlePayQrCode,
      receiptValueDialog,
      newReceiptValueForm,
      handleReceiptValue,
    };
  },
});
</script>
