import { defineStore } from "pinia";

import { useFavicon } from "@vueuse/core";

import type { DomainProps } from "@/types/props/domain.props";

const favicon = useFavicon();

export const useWhiteLabelStore = defineStore("whitelabel", {
  state: () => ({
    logo: "",
    domain: {
      nome: "",
      favicon: "",
      logo: "",
      primary_color: "#757fef",
      secondary_color: "#ee368c",
    } as DomainProps,
  }),
  actions: {
    setTitle(title: string) {
      document.title = title;
    },
    setDomainData(domainProps: DomainProps) {
      this.domain = domainProps;
    },
    setLogoImage(logoUrl: string) {
      this.logo = logoUrl;
    },
    setFavicon(faviconUrl: string) {
      favicon.value = faviconUrl;
    },
    setTheme(primaryColor: string, secondaryColor: string) {
      (this.domain.primary_color = primaryColor),
        (this.domain.secondary_color = secondaryColor);
    },
  },
});
