import BaseService from "@/shared/base.service";

import type { RateZeroResponse } from "@/types/responses/ratezero";

export class RateZeroService {
  static async GetAllRates(
    empresa_id?: number,
    init_date?: string,
    finish_date?: string,
    player_id?: number,
    board_id?: number,
    document?: string,
    document_account?: string,
    status?: string
  ): Promise<RateZeroResponse[] | []> {
    try {
      const params = {
        empresa_id,
        init_date,
        finish_date,
        player_id,
        board_id,
        document,
        document_account,
        status,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<RateZeroResponse[]>(
        `financial/rate_zero`,
        queryParams
      );

      return response ?? [];
    } catch (error) {
      console.error("Error in RateZeroService:", error);
      return [];
    }
  }

  static async ApproveRateZero(id: number): Promise<object | null> {
    try {
      const response = await BaseService.post<object>(
        `financial/rate_zero_approved/${id}`
      );
      return response ?? {};
    } catch (error) {
      console.error("Error in ApproveRateZero:", error);
      return null;
    }
  }

  static async ReproveRateZero(id: number): Promise<object | null> {
    try {
      const response = await BaseService.post<object>(
        `financial/rate_zero_reproved/${id}`
      );
      return response ?? {};
    } catch (error) {
      console.error("Error in ReproveRateZero:", error);
      return null;
    }
  }
}
