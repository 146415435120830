import BaseService from "@/shared/base.service";

export class ClerkService {
  static async CreateClerkPixKey(id: number): Promise<string | null> {
    try {
      const response = await BaseService.post<string>(
        `clerk_pix_key/create_pix/${id}`
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in CreateClerkPixKey:", error);
      return null;
    }
  }
}
