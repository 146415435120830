import { defineStore } from "pinia";

import type { DashboardFilters } from "@/types/requests/dashboardFilters";

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
    filters: {
      init_date: "",
      finish_date: "",
      empresa_id: 0,
    },
  }),
  actions: {
    setFilters(newFilters: DashboardFilters) {
      this.filters = newFilters;
    },
  },
});
