import { AccountService } from "@/services";

import { isLoading } from "@/shared/base.service";

export function useBalance() {
  async function getBalance(id: number) {
    try {
      const response = await AccountService.GetBalance(id);
      if (response) return response.balance;

      return 0;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    getBalance,
  };
}
