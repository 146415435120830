<template>
  <div
    class="breadcrumb-card mb-25 d-md-flex align-items-center justify-space-between"
  >
    <h5 class="mb-0">{{ pageTitle }}</h5>
    <ol class="breadcrumb mb-0 ps-0">
      <li class="breadcrumb-item">
        <router-link to="/">
          <i class="ri-home-8-line"></i>
          Dashboard
        </router-link>
      </li>
      <li v-if="route.path != '/'" class="breadcrumb-item fw-semibold">
        {{ mainTitle }}
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useRoute } from "vue-router";

export default defineComponent({
  name: "PageTitle",
  props: ["pageTitle", "mainTitle"],
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
});
</script>
