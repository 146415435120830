import BaseService from "@/shared/base.service";

import type { User } from "@/types/models/user";

import type { NewJogador } from "@/types/requests/newJogador";
import type { NewMesario } from "@/types/requests/newMesario";
import type { NewProdutor } from "@/types/requests/newProdutor";

import type { NewJogadorResponse } from "@/types/responses/newJogador";
import type { NewMesarioResponse } from "@/types/responses/newMesario";
import type { NewProdutorResponse } from "@/types/responses/newProdutor";

import { AxiosRequestConfig } from "axios";

export class UserService {
  static async GetAllUsers(
    type: "jogador" | "mesario" | "produtor"
  ): Promise<User[] | []> {
    try {
      const response = await BaseService.get<User[]>("user", { type: type });
      return response ?? [];
    } catch (error) {
      console.error("Error in GetAllUsers:", error);
      return [];
    }
  }

  static async GetUserById(id: number): Promise<User | null> {
    try {
      const response = await BaseService.get<User>(`user/${id}`);
      return response ?? null;
    } catch (error) {
      console.error("Error in GetUserById:", error);
      return null;
    }
  }

  static async CreateJogador(
    payload: NewJogador
  ): Promise<NewJogadorResponse | null> {
    try {
      const response = await BaseService.post<NewJogadorResponse>(
        "user-account/register",
        payload
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in CreateJogador:", error);
      return null;
    }
  }

  static convertNewJogadorToFormData(payload: NewJogador): FormData {
    const formData = new FormData();

    formData.append("cpf", payload.cpf);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    // formData.append("type", payload.type);
    formData.append("password", payload.password);
    formData.append("password_confirmation", payload.password_confirmation);
    formData.append("accountable", payload.accountable);

    if (payload.board_id != 0) {
      formData.append("board_id", payload.board_id.toString());
    }

    formData.append(
      "account[accountable_cpf]",
      payload.account.accountable_cpf
    );
    formData.append("account[accountable]", payload.account.accountable);
    formData.append("account[mother_name]", payload.account.mother_name);
    formData.append("account[birth_date]", payload.account.birth_date);
    formData.append("account[father_name]", payload.account.father_name);
    formData.append("account[phone]", payload.account.phone);
    formData.append("account[email]", payload.account.email);
    formData.append("account[account_type]", payload.account.account_type);
    formData.append("account[zipcode]", payload.account.zipcode);
    formData.append("account[street]", payload.account.street);
    formData.append("account[street_number]", payload.account.street_number);
    formData.append(
      "account[street_complement]",
      payload.account.street_complement
    );
    formData.append("account[neighborhood]", payload.account.neighborhood);
    formData.append("account[state]", payload.account.state);
    formData.append("account[city]", payload.account.city);

    if (payload.account.pictureA) {
      formData.append("account[pictureA]", payload.account.pictureA);
    }
    if (payload.account.pictureB) {
      formData.append("account[pictureB]", payload.account.pictureB);
    }
    formData.append("account[account_type]", payload.account.account_type);

    return formData;
  }

  static convertNewMesarioToFormData(payload: NewMesario): FormData {
    const formData = new FormData();

    formData.append("cpf", payload.cpf);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    // formData.append("type", payload.type);
    formData.append("password", payload.password);
    formData.append("password_confirmation", payload.password_confirmation);
    formData.append("accountable", payload.accountable);
    formData.append("empresa_id", payload.empresa_id.toString());

    formData.append(
      "account[accountable_cpf]",
      payload.account.accountable_cpf
    );
    formData.append("account[accountable]", payload.account.accountable);
    formData.append("account[mother_name]", payload.account.mother_name);
    formData.append("account[birth_date]", payload.account.birth_date);
    formData.append("account[father_name]", payload.account.father_name);
    formData.append("account[phone]", payload.account.phone);
    formData.append("account[email]", payload.account.email);
    formData.append("account[account_type]", payload.account.account_type);
    formData.append("account[zipcode]", payload.account.zipcode);
    formData.append("account[street]", payload.account.street);
    formData.append("account[street_number]", payload.account.street_number);
    formData.append(
      "account[street_complement]",
      payload.account.street_complement
    );
    formData.append("account[neighborhood]", payload.account.neighborhood);
    formData.append("account[state]", payload.account.state);
    formData.append("account[city]", payload.account.city);

    if (payload.account.pictureA) {
      formData.append("account[pictureA]", payload.account.pictureA);
    }
    if (payload.account.pictureB) {
      formData.append("account[pictureB]", payload.account.pictureB);
    }
    formData.append("account[account_type]", payload.account.account_type);

    return formData;
  }

  static async CreateJogadorWithKYC(
    payload: NewJogador
  ): Promise<NewJogadorResponse | null> {
    try {
      const formData = UserService.convertNewJogadorToFormData(payload);
      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await BaseService.post<NewJogadorResponse>(
        "user-account/register",
        formData,
        config
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in CreateJogadorWithKYC:", error);
      return null;
    }
  }

  static async UpdateJogador(
    id: number,
    payload: object
  ): Promise<string | null> {
    try {
      const response = await BaseService.put<string>(`player/${id}`, payload);
      return response ?? null;
    } catch (error) {
      console.error("Error in UpdateJogador:", error);
      return null;
    }
  }

  static async CreateMesario(
    payload: NewMesario
  ): Promise<NewMesarioResponse | null> {
    try {
      const response = await BaseService.post<NewMesarioResponse>(
        "user-account/register",
        payload
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in CreateMesario:", error);
      return null;
    }
  }

  static async CreateMesarioWithKYC(
    payload: NewMesario
  ): Promise<NewMesarioResponse | null> {
    try {
      const formData = UserService.convertNewMesarioToFormData(payload);
      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await BaseService.post<NewMesarioResponse>(
        "user-account/register",
        formData,
        config
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in CreateMesarioWithKYC:", error);
      return null;
    }
  }

  static async UpdateMesario(
    id: number,
    payload: object
  ): Promise<string | null> {
    try {
      const response = await BaseService.put<string>(`clerk/${id}`, payload);
      return response ?? null;
    } catch (error) {
      console.error("Error in UpdateMesario:", error);
      return null;
    }
  }

  static async CreateProdutor(
    payload: NewProdutor
  ): Promise<NewProdutorResponse | null> {
    try {
      const response = await BaseService.post<NewProdutorResponse>(
        "user",
        payload
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in CreateProdutor:", error);
      return null;
    }
  }

  static async ActiveUser(id: number): Promise<string | null> {
    try {
      const response = await BaseService.post<string>(`active_user/${id}`);
      return response ?? null;
    } catch (error) {
      console.error("Error in ActiveUser:", error);
      return null;
    }
  }

  static async InactiveUser(id: number): Promise<string | null> {
    try {
      const response = await BaseService.post<string>(`inactive_user/${id}`);
      return response ?? null;
    } catch (error) {
      console.error("Error in InactiveUser:", error);
      return null;
    }
  }
}
