<template>
  <div class="items-per-page d-flex justify-space-between">
    <div class="d-flex align-items-center justify-content-center">
      <div class="mr-5">Itens por página:</div>
      <v-select
        v-model="select"
        :hide-details="true"
        item-title="state"
        :items="['5', '10', '20', '50']"
      />
    </div>

    <v-pagination
      :length="100"
      :total-visible="3"
      v-model="page"
      rounded="circle"
    />
  </div>
</template>

<script>
export default {
  name: "ItemsPerPage",
  data() {
    return {
      page: 1,
      select: { state: "0" },
    };
  },
  watch: {
    page(page) {
      this.$emit("changePage", {
        page: page,
      });
    },
  },
};
</script>
