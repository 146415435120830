import { computed, reactive } from "vue";

import router from "@/router";

import { minLength, required, email, helpers } from "@vuelidate/validators";

import { useAuthStore } from "@/stores/auth.store";
import { useSettingsStore } from "@/stores/settings.store";

import { AuthService } from "@/services";

import { isLoading } from "@/shared/base.service";

export function useAuth() {
  const signInFormRules = computed(() => {
    return {
      email: {
        required: helpers.withMessage(
          () => `O campo email é obrigatório`,
          required
        ),
        email: helpers.withMessage(() => `Informe um email válido`, email),
      },
      password: {
        required: helpers.withMessage(
          () => `O campo senha é obrigatório`,
          required
        ),
        minLength: helpers.withMessage(
          () => `O campo deve ter ao menos 6 caracteres`,
          minLength(6)
        ),
      },
    };
  });

  const signInForm = reactive({
    email: "",
    password: "",
  });

  async function signIn(email: string, password: string) {
    try {
      const response = await AuthService.Login(email, password);

      if (response?.user.type != "admin") {
        const isInMaintenance = useSettingsStore().getMaintenanceMode;
        if (isInMaintenance == true) {
          router.push({ name: "MaintenancePage" });

          return;
        }
      }

      if (response) {
        useAuthStore().setUser(response.user);
        useAuthStore().setToken(response.token);
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function logout() {
    try {
      const response = await AuthService.Logout();

      if (response) useAuthStore().setLogout();

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    signInFormRules,
    signInForm,
    signIn,
    logout,
  };
}
