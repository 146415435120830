<template>
  <PageTitle pageTitle="Dashboard" mainTitle="Dashboard" />

  <div class="pingo-form-group mb-0">
    <v-row>
      <v-col cols="12" lg="12" xl="12">
        <DashboardFilters />
        <AdminStats v-if="user.type == 'admin'" />
        <CounterAcountStats />
        <AccountFinancialsStats />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue";

import { useAuthStore } from "@/stores/auth.store";

import PageTitle from "@/components/Common/PageTitle.vue";

import DashboardFilters from "@/components/Dashboard/Filters/DashboardFilters.vue";

import AdminStats from "@/components/Dashboard/Admin/AdminStats.vue";
import CounterAcountStats from "@/components/Dashboard/Common/CounterAcountStats.vue";
import AccountFinancialsStats from "@/components/Dashboard/Common/AccountFinancialsStats.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    PageTitle,
    DashboardFilters,
    AdminStats,
    CounterAcountStats,
    AccountFinancialsStats,
  },
  setup() {
    const authStore = useAuthStore();

    const user: Ref = ref(authStore.getUser);

    return {
      user,
    };
  },
});
</script>
