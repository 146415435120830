<template>
  <div
    v-if="isLoadingPlayerBalance"
    class="balance-btn fw-semibold position-relative px-4"
  >
    Carregando...
  </div>

  <div v-if="!isLoadingPlayerBalance" class="balance-btn px-4 fw-semibold">
    Saldo atual: {{ formattedBalance }}

    <v-btn size="x-small" variant="text" @click="fetchPlayerBalance()">
      <i class="ri-restart-line"></i>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import { useAuthStore } from "@/stores/auth.store";

import { useBalance } from "@/composables/useBalance";

export default defineComponent({
  name: "CurrentBalance",
  setup() {
    const authStore = useAuthStore();

    const { getBalance } = useBalance();

    const user: Ref = ref(authStore.getUser);

    const isLoadingPlayerBalance = ref(false);

    const formattedBalance = ref();

    const fetchPlayerBalance = async () => {
      try {
        isLoadingPlayerBalance.value = true;

        const balance = (await getBalance(user.value.id)) ?? 0;

        formattedBalance.value = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(balance);
      } catch (error) {
        console.error("Erro ao buscar dados de saldo do jogador:", error);
      } finally {
        isLoadingPlayerBalance.value = false;
      }
    };

    onMounted(() => {
      fetchPlayerBalance();
    });

    return {
      formattedBalance,
      isLoadingPlayerBalance,
      fetchPlayerBalance,
    };
  },
});
</script>

<style lang="scss" scoped>
.balance-btn {
  border: 1px solid #009688;
  border-radius: 4px;
  font-size: 15px;
  color: #009688;
  padding: {
    top: 11px;
    left: 38px;
    right: 15px;
    bottom: 11px;
  }
  i {
    color: #009688;
    top: 50%;
    left: 15px;
    line-height: 1;
    position: absolute;
    transform: translateY(-50%);
  }
}
</style>
