import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_component_v_alert, {
    class: "alert alert-danger d-flex justify-space-between align-items-center",
    closable: "",
    "onClick:close": _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeAlert()))
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.error), 1)
    ]),
    _: 1
  }))
}