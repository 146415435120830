import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createBlock(_component_v_autocomplete, {
    label: "Selecione uma Empresa",
    loading: _ctx.isLoadingCompaniesInfo,
    "item-value": 'id',
    "item-title": 'nome',
    items: _ctx.companies,
    modelValue: _ctx.company,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.company) = $event)),
      _cache[2] || (_cache[2] = (value) => _ctx.$emit('update:value', value))
    ],
    readonly: _ctx.readonly,
    "hide-details": !_ctx.required,
    "no-data-text": 
      _ctx.isLoadingCompaniesInfo == true ? 'Carregando...' : 'Não há nenhuma empresa'
    ,
    "onUpdate:menu": _cache[1] || (_cache[1] = (value) => (_ctx.isSelectMenuOpen = value))
  }, {
    "no-data": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.isLoadingCompaniesInfo ? "Carregando..." : "Não há nenhuma empresa"), 1)
    ]),
    _: 1
  }, 8, ["loading", "items", "modelValue", "readonly", "hide-details", "no-data-text"]))
}