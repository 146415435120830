import { defineStore } from "pinia";

export const useLoadingDialog = defineStore({
  id: "loading",
  state: () => ({
    isLoading: false,
    loadingMessage: "Carregando...",
  }),
  actions: {
    setLoading(message: string) {
      this.isLoading = true;
      this.loadingMessage = message;
    },
    setLoadingOff() {
      this.isLoading = false;
    },
  },
});
