<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <button
        class="profile-btn main-color border-none bg-transparent position-relative"
        v-bind="props"
      >
        <v-img
          src="@/assets/img/admin.jpg"
          class="rounded-circle"
          alt="admin"
        />

        {{ userType }}
        <span></span>
        <span class="fw-semibold">Olá, {{ user.name ?? "Usuário" }}</span>
      </button>
    </template>

    <v-list class="profile-menu-dropdown w-100">
      <ul class="m-0">
        <li>
          <router-link
            v-if="user.type == 'jogador' || user.type == 'mesario'"
            to="/configuracoes/conta"
          >
            <i class="ri-settings-3-line"></i>
            Minha Conta
          </router-link>
        </li>

        <li>
          <router-link to="/configuracoes/perfil">
            <i class="ri-pencil-line"></i>
            Editar Perfil
          </router-link>
        </li>

        <li>
          <router-link to="/configuracoes/senha">
            <i class="ri-lock-line"></i>
            Alterar Senha
          </router-link>
        </li>

        <li>
          <router-link
            v-if="user.type == 'jogador' || user.type == 'mesario'"
            to="/configuracoes/pix"
          >
            <i class="ri-user-3-line"></i>
            Contas PIX
          </router-link>
        </li>

        <v-dialog
          v-if="user.type == 'admin'"
          v-model="maintenanceModeDialog"
          width="600"
          scrollable
        >
          <template v-slot:activator="{ props: activatorProps }">
            <li class="text-red-darken-3" v-bind="activatorProps">
              <router-link to="/">
                <i class="ri-settings-2-line"></i>
                Modo manutenção (Status:
                {{ maintenanceStatus ? "Ativo" : "Inativo" }})
              </router-link>
            </li>
          </template>

          <template v-slot:default="{ isActive }">
            <v-card
              class="py-0"
              title="Tem certeza que deseja alterar o modo manutenção"
            >
              <v-divider></v-divider>

              <v-card-text class="font-weight-light px-4 py-2">
                <span v-if="maintenanceStatus == true"
                  >Ao desativar o modo manutenção, todos os usuários poderão
                  logar na plataforma.
                </span>

                <span v-if="maintenanceStatus == false"
                  >Ao ativar o modo manutenção, somente admins poderão logar na
                  plataforma.
                </span>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn class="text-capitalize" @click="isActive.value = false">
                  Fechar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  color="red-darken-3"
                  variant="tonal"
                  @click="handleUpdateMaintenceMode()"
                >
                  Sim
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <li v-on:click="handleLogout()">
          <router-link to="/">
            <i class="ri-shut-down-line"></i>
            Sair da conta
          </router-link>
        </li>
      </ul>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import router from "@/router";

import { useSettingsStore } from "@/stores/settings.store";
import { useAuthStore } from "@/stores/auth.store";
import { useLoadingDialog } from "@/stores/loading.store";
import { useSnackbarStore } from "@/stores/snackbar.store";

import { useAuth } from "@/composables/useAuth";
import { useSettings } from "@/composables/useSettings";

export default defineComponent({
  name: "AdminProfile",
  components: {},
  setup() {
    const authStore = useAuthStore();
    const loadingStore = useLoadingDialog();
    const snackbarStore = useSnackbarStore();
    const settingStore = useSettingsStore();

    const { logout } = useAuth();
    const { checkMaintenanceStatus, setMaintenanceMode } = useSettings();

    const user: Ref = ref(authStore.getUser);
    const userType = ref();

    const maintenanceStatus = ref(useSettingsStore().getMaintenanceMode);

    const maintenanceModeDialog = ref(false);

    const getUserType = (userType: string) => {
      switch (userType) {
        case "admin":
          return "Administrador";
        case "mesario":
          return "Mesário";
        case "produtor":
          return "Produtor";
        case "jogador":
          return "Jogador";
        default:
          return "Tipo de usuário desconhecido";
      }
    };

    const handleUpdateMaintenceMode = async () => {
      loadingStore.setLoading("Um momento...");

      try {
        if (useSettingsStore().getMaintenanceMode == false) {
          const response = await setMaintenanceMode("ativo");

          if (response) {
            maintenanceModeDialog.value = false;
            useSettingsStore().setMaintenanceMode(false);
            maintenanceStatus.value = true;

            snackbarStore.showSnackbar("Modo manutenção ativado com sucesso.");
          }
        }

        if (useSettingsStore().getMaintenanceMode == true) {
          const response = await setMaintenanceMode("inativo");
          if (response) {
            maintenanceModeDialog.value = false;
            useSettingsStore().setMaintenanceMode(true);
            maintenanceStatus.value = false;

            snackbarStore.showSnackbar(
              "Modo manutenção desativado com sucesso."
            );
          }
        }
      } catch (error) {
        console.error("Erro ao atualizar modo manutenção:", error);
      }

      loadingStore.setLoadingOff();
    };

    const handleLogout = async () => {
      logout().then(() => {
        authStore.setLogout();

        router.push({ name: "LoginPage" }).then(() => {
          router.go(0);
        });
      });
    };

    onMounted(async () => {
      userType.value = getUserType(user.value.type);

      await checkMaintenanceStatus();
    });

    return {
      user,
      userType,
      maintenanceModeDialog,
      maintenanceStatus,
      handleUpdateMaintenceMode,
      handleLogout,
    };
  },
});
</script>
