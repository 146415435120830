import BaseService from "@/shared/base.service";

import type { ExtractResponse } from "@/types/responses/extract";
import type {
  ExtractIpExcelResponse,
  ExtractIpResponse,
} from "@/types/responses/extractIp";
import type { ExtractListSimplifiedResponse } from "@/types/responses/extractListSimplified";

export class ExtractService {
  static async GetAllExtract(
    init_date?: string,
    finish_date?: string,
    document_destiny?: string,
    description?: string,
    player_destiny?: number,
    with_conta_aposta?: boolean,
    type?: "credit" | "debit",
    player_id?: number,
    document?: string,
    empresa_id?: number,
    board_id?: number
  ): Promise<ExtractResponse | null> {
    try {
      const params = {
        init_date,
        finish_date,
        document_destiny,
        description,
        player_destiny,
        with_conta_aposta,
        type,
        player_id,
        document,
        empresa_id,
        board_id,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<ExtractResponse>(
        `extract/list`,
        queryParams
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllExtract:", error);
      return null;
    }
  }

  static async GetAllExtractIp(
    page: number,
    player_id?: number,
    document?: string
  ): Promise<ExtractIpResponse | null> {
    try {
      const params = {
        page,
        player_id,
        document,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<ExtractIpResponse>(
        `extract/getExtract`,
        queryParams
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllExtractIp:", error);
      return null;
    }
  }

  static async GetExtractIpExcel(
    page: number,
    player_id?: number,
    document?: string
  ): Promise<ExtractIpExcelResponse | null> {
    try {
      const params = {
        page,
        player_id,
        document,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<ExtractIpExcelResponse>(
        `extract/getExtractExcel`,
        queryParams
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetExtractIpExcel:", error);
      return null;
    }
  }

  static async GetListSimplified(
    accountId: number
  ): Promise<ExtractListSimplifiedResponse[]> {
    try {
      const response = await BaseService.get<ExtractListSimplifiedResponse[]>(
        `extract/listSimplified/${accountId}`
      );

      return response ?? [];
    } catch (error) {
      console.error("Error in GetListSimplified:", error);
      return [];
    }
  }
}
