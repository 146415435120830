<template>
  <div class="pingo-table-content">
    <div class="pingo-table v-elevation-z8">
      <v-table>
        <thead>
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="index"
              :class="column == 'id' ? 'text-start' : 'text-center'"
            >
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <slot name="data"></slot>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableComponent",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
});
</script>
