import { ref } from "vue";

import { CompanyService } from "@/services";

import { isLoading } from "@/shared/base.service";

import type { Company } from "@/types/models/company";

export function useCompany() {
  const company = ref<Company>();
  const companies = ref<Company[]>([]);

  async function getAllCompanies() {
    try {
      const response = await CompanyService.GetAllCompanies();
      companies.value = response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    company,
    companies,
    getAllCompanies,
  };
}
