<template>
  <AlertComponent
    v-if="alert.show"
    style="position: fixed; right: 2%; top: 2%; z-index: 999"
    :message="alert.message"
    :type="alert.type"
    @close-alert="(e) => (alert.show = !e)"
  />

  <div class="text-center pa-4">
    <v-dialog v-model="isDialogOpen" width="auto">
      <v-card
        max-width="450"
        prepend-icon="mdi-update"
        text="Insira o valor que deseja transferir de saldo."
        title="Adicionar Saldo"
      >
        <div class="pingo-form-group without-icon pl-5 pr-5">
          <MonetaryInput
            :label="'Saldo'"
            v-model="amount"
            @update:value="(value: string) => updateInitValue(value)"
          />
        </div>

        <v-divider></v-divider>

        <template v-slot:actions>
          <v-btn class="ms-auto border" @click="closeDialog()">Fechar</v-btn>

          <v-btn
            prepend-icon="mdi-check-circle"
            class="mr-5 border"
            @click="save(data)"
          >
            <template v-slot:prepend>
              <v-icon color="success"></v-icon>
            </template>
            Salvar
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import MonetaryInput from "@/components/Forms/AdvancedElements/MonetaryInput.vue";

import { useAccounts } from "@/composables/useAccounts";

import AlertComponent from "@/components/Alerts/AlertComponent.vue";

export default defineComponent({
  name: "AddBalance",
  props: {
    open: {
      type: Boolean,
      default: false,
      required: true,
    },
    data: {
      type: Number,
      required: true,
    },
  },
  components: {
    MonetaryInput,
    AlertComponent,
  },
  emits: ["close-dialog"],
  setup(props, { emit }) {
    const { addBalance } = useAccounts();

    const isDialogOpen = ref(false);

    const alert = ref({
      show: false,
      type: String as () => "success" | "error" | "warning" | "info",
      message: "",
    });

    const amount = ref<number>();

    const updateInitValue = (value: string) =>
      (amount.value = parseInt(
        value.replace("R$", "").replace(/\./g, "").replace(",", ".").trim()
      ));

    const closeDialog = () => {
      isDialogOpen.value = false;
      emit("close-dialog", isDialogOpen.value);
    };

    const save = async (data: number | any) => {
      await addBalance({
        selectedAccount: data,
        amount: amount.value,
      })
        .then((result) => {
          closeDialog();

          if (result.error) {
            alert.value = {
              message: result.message,
              type: String as () => "error",
              show: true,
            };

            return false;
          }

          alert.value = {
            message: "Saldo adicionado",
            type: String as () => "success",
            show: true,
          };

          return true;
        })
        .catch((err) => {
          alert.value = {
            message: err,
            type: String as () => "error",
            show: true,
          };
        });
    };

    watch(
      () => props.open,
      (newValue) => {
        isDialogOpen.value = newValue;
      }
    );

    return {
      isDialogOpen,
      alert,
      amount,
      updateInitValue,
      closeDialog,
      save,
    };
  },
});
</script>
