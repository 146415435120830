import BaseService from "@/shared/base.service";

import type { Withdraw } from "@/types/models/withdraw";

export class WithdrawService {
  static async GetAllWithdraws(
    board_id?: number,
    player_id?: number,
    name?: string,
    accountable_cpf?: number,
    status?: string,
    init_date_create?: string,
    finish_date_create?: string,
    init_date_update?: string,
    finish_date_update?: string
  ): Promise<Withdraw[] | []> {
    try {
      const response = await BaseService.get<Withdraw[]>(`withdraw`, {
        board_id: board_id,
        player_id: player_id,
        name: name,
        accountable_cpf: accountable_cpf,
        status: status,
        init_date_create: init_date_create,
        finish_date_create: finish_date_create,
        init_date_update: init_date_update,
        finish_date_update: finish_date_update,
      });
      return response ?? [];
    } catch (error) {
      console.error("Error in GetAllWithdraws:", error);
      return [];
    }
  }

  static async NewWithdrawRequest(payload: object): Promise<object | null> {
    try {
      const response = await BaseService.post<object>("withdraw", payload);
      return response ?? null;
    } catch (error) {
      console.error("Error in NewWithdrawRequest:", error);
      return null;
    }
  }

  static async ApproveWithdraw(payload: object): Promise<object | null> {
    try {
      const response = await BaseService.post<object>(
        "withdraw/approve",
        payload
      );
      return response ?? {};
    } catch (error) {
      console.error("Error in ApproveWithdraw:", error);
      return null;
    }
  }

  static async RefuseWithdraw(payload: object): Promise<object | null> {
    try {
      const response = await BaseService.post<object>(
        "withdraw/refuse",
        payload
      );
      return response ?? {};
    } catch (error) {
      console.error("Error in RefuseWithdraw:", error);
      return {};
    }
  }
}
