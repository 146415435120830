import { reactive } from "vue";

interface StateStore {
  open: boolean;
  showError: boolean;
  errorMessage: string;
  onChange: () => void;
}

const stateStore: StateStore = reactive({
  open: false,
  showError: false,
  errorMessage: "Ocorreu um erro",
  onChange() {
    stateStore.open = !stateStore.open;
  },
});

export default stateStore;
