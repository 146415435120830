<template>
  <v-row>
    <!-- Balance -->
    <v-col cols="12" lg="4" md="4">
      <v-card class="single-stats-box pingo-card">
        <div class="d-flex justify-space-between align-items-center">
          <div v-if="isLoadingAccountFinancialsInfo" class="w-100">
            <v-skeleton-loader
              class="mb-2"
              type="chip"
              style="height: 42px; width: 75%"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="w-50"
              type="text"
              style="height: 22.5px"
            ></v-skeleton-loader>
          </div>

          <div v-if="!isLoadingAccountFinancialsInfo" class="title">
            <h3>
              {{
                new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(accountFinancials.counterPaid)
              }}
            </h3>
            <span class="d-block gray-color">Valor Pago</span>
          </div>

          <div class="icon">
            <i class="flaticon-donut-chart"></i>
          </div>
        </div>

        <!-- <p class="position-relative up mb-0">
          <i class="flaticon-calendar" style="font-size: 18px"></i>
          <span>1.3%</span> Up from past week
        </p> -->
      </v-card>
    </v-col>

    <!-- Players -->
    <v-col cols="12" lg="4" md="4">
      <v-card class="single-stats-box pingo-card">
        <div class="d-flex justify-space-between align-items-center">
          <div v-if="isLoadingAccountFinancialsInfo" class="w-100">
            <v-skeleton-loader
              class="mb-2"
              type="chip"
              style="height: 42px; width: 75%"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="w-50"
              type="text"
              style="height: 22.5px"
            ></v-skeleton-loader>
          </div>

          <div v-if="!isLoadingAccountFinancialsInfo" class="title">
            <h3>
              {{
                new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(accountFinancials.counterPending)
              }}
            </h3>
            <span class="d-block gray-color">Valor Pendente</span>
          </div>

          <div class="icon">
            <i class="flaticon-goal"></i>
          </div>
        </div>

        <!-- <p class="position-relative up mb-0">
          <i class="flaticon-trend"></i> <span>0.5%</span> Up from past week
        </p> -->
      </v-card>
    </v-col>

    <!-- Accounts -->
    <v-col cols="12" lg="4" md="4">
      <v-card class="single-stats-box pingo-card">
        <div class="d-flex justify-space-between align-items-center">
          <div v-if="isLoadingAccountFinancialsInfo" class="w-100">
            <v-skeleton-loader
              class="mb-2"
              type="chip"
              style="height: 42px; width: 75%"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="w-50"
              type="text"
              style="height: 22.5px"
            ></v-skeleton-loader>
          </div>

          <div v-if="!isLoadingAccountFinancialsInfo" class="title">
            <h3>
              {{
                new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(accountFinancials.counterTotal)
              }}
            </h3>
            <span class="d-block gray-color">Valor Total</span>
          </div>

          <div class="icon">
            <i class="flaticon-award"></i>
          </div>
        </div>

        <!-- <p class="position-relative down mb-0">
          <i class="flaticon-trend-1"></i> <span>1%</span> Down from past week
        </p> -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";

import { useDashboardStore } from "@/stores/dashboard.store";

import { useDashboard } from "@/composables/useDashboard";

export default defineComponent({
  name: "AccountFinancialsStats",
  setup() {
    const dashboardStore = useDashboardStore();

    const { accountFinancials, getAcountFinancials } = useDashboard();

    const isLoadingAccountFinancialsInfo = ref(true);

    const fetchCommonStatsData = async () => {
      try {
        getAcountFinancials().then(() => {
          isLoadingAccountFinancialsInfo.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados do dashboard:", error);
      }
    };

    watch(
      () => dashboardStore.filters,
      (newFilters) => {
        isLoadingAccountFinancialsInfo.value = true;

        getAcountFinancials(
          newFilters.init_date,
          newFilters.finish_date,
          newFilters.empresa_id
        ).then(() => {
          isLoadingAccountFinancialsInfo.value = false;
        });
      },
      { deep: true }
    );

    onMounted(() => {
      fetchCommonStatsData();
    });

    return {
      isLoadingAccountFinancialsInfo,
      accountFinancials,
    };
  },
});
</script>

<style lang="scss" scoped>
.single-stats-box {
  .title {
    h3 {
      margin-bottom: 6px;
      font: {
        size: 28px;
        family: var(--fontFamily) !important;
      }
    }
  }
  .icon {
    background: rgba(85, 112, 241, 0.12);
    transition: var(--transition);
    color: var(--heraBlueColor);
    border-radius: 10px;
    text-align: center;
    position: relative;
    font-size: 35px;
    height: 60px;
    width: 60px;
    min-width: 60px;

    i {
      left: 0;
      right: 0;
      top: 50%;
      line-height: 1;
      margin-top: 1px;
      position: absolute;
      transform: translateY(-50%);
    }
  }
  p {
    line-height: initial;
    padding-left: 30px;
    margin-top: 25px;
    font: {
      weight: 600;
      size: 13px;
    }
    span {
      color: var(--blackColor);
    }
    i {
      transform: translateY(-50%);
      color: var(--blackColor);
      position: absolute;
      margin-top: 2px;
      line-height: 1;
      top: 50%;
      left: 0;
      font: {
        size: 25px;
        weight: 700;
      }
    }
    &.up {
      span {
        color: var(--aareRiverColor);
      }
      i {
        color: var(--aareRiverColor);
      }
    }
    &.down {
      span {
        color: #ee368c;
      }
      i {
        color: #ee368c;
      }
    }
  }
  &:hover {
    .icon {
      color: var(--whiteColor);
      background-color: var(--heraBlueColor);
    }
  }
}

@media only screen and (max-width: 767px) {
  .single-stats-box {
    .title {
      h3 {
        margin-bottom: 5px;
        font-size: 20px;
      }
    }
    .icon {
      border-radius: 5px;
      font-size: 30px;
      height: 58px;
      width: 58px;
    }
    p {
      padding-left: 29px;
      margin-top: 10px;

      i {
        font-size: 24px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-stats-box {
    .title {
      h3 {
        margin-bottom: 5px;
        font-size: 22px;
      }
    }
    .icon {
      font-size: 30px;
      height: 55px;
      width: 55px;
    }
    p {
      padding-left: 23px;
      margin-top: 15px;

      i {
        font-size: 20px;
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .single-stats-box {
    p {
      font-size: var(--fontSize);
    }
  }
}
</style>
