import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pingo-table-content" }
const _hoisted_2 = { class: "pingo-table v-elevation-z8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_table, null, {
        default: _withCtx(() => [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: index,
                  class: _normalizeClass(column == 'id' ? 'text-start' : 'text-center')
                }, _toDisplayString(column), 3))
              }), 128))
            ])
          ]),
          _createElementVNode("tbody", null, [
            _renderSlot(_ctx.$slots, "data")
          ])
        ]),
        _: 3
      })
    ])
  ]))
}