<template>
  <form class="mb-6" @submit.prevent="handleFilter()">
    <v-row>
      <!-- Filters -->
      <v-col class="py-0" cols="12" lg="12" md="12">
        <v-card class="single-stats-box pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Filtros</h5>
          </div>

          <v-row>
            <v-col cols="12" lg="3">
              <div class="pingo-form-group without-icon">
                <!-- <DateInput
                  :label="'Data Inicial'"
                  :value="init_date"
                  :required="false"
                  @update:value="
                    updateInitDate($event.toISOString().substring(0, 10))
                  "
                /> -->

                <TextInput
                  :type="'date'"
                  :label="'Data Inicial'"
                  v-model="init_date"
                  :required="false"
                  @update:value="
                    updateInitDate($event.toISOString().substring(0, 10))
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="3">
              <div class="pingo-form-group without-icon">
                <!-- <DateInput
                  :label="'Data Final'"
                  :value="finish_date"
                  :required="false"
                  @update:value="
                    updateFinishDate($event.toISOString().substring(0, 10))
                  "
                /> -->

                <TextInput
                  :type="'date'"
                  :label="'Data Final'"
                  :required="false"
                  v-model="finish_date"
                  @update:value="
                    updateFinishDate($event.toISOString().substring(0, 10))
                  "
                />
              </div>
            </v-col>

            <v-col v-if="user.type == 'admin'" cols="12" lg="3">
              <div class="pingo-form-group without-icon">
                <SelectCompany
                  :required="false"
                  @update:value="updateCompany($event)"
                />
              </div>
            </v-col>

            <v-col cols="12" lg="3" class="mt-auto mb-auto">
              <v-btn
                class="w-100"
                type="submit"
                size="large"
                variant="outlined"
              >
                Aplicar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </form>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue";

import { useAuthStore } from "@/stores/auth.store";
import { useDashboardStore } from "@/stores/dashboard.store";

import SelectCompany from "@/components/Forms/Select/SelectCompany.vue";

// import DateInput from "@/components/Forms/BasicElements/DateInput.vue";
import TextInput from "@/components/Forms/BasicElements/TextInput.vue";

export default defineComponent({
  name: "DashboardFilters",
  components: {
    // DateInput,
    TextInput,
    SelectCompany,
  },
  setup() {
    const authStore = useAuthStore();
    const dashboardStore = useDashboardStore();

    const user: Ref = ref(authStore.getUser);

    const init_date = ref(dashboardStore.filters.init_date);
    const finish_date = ref(dashboardStore.filters.finish_date);
    const empresa_id = ref(dashboardStore.filters.empresa_id);

    const updateInitDate = (value: string) => (init_date.value = value);

    const updateFinishDate = (value: string) => (finish_date.value = value);

    const updateCompany = (value: number) => (empresa_id.value = value);

    const handleFilter = () => {
      dashboardStore.setFilters({
        init_date: init_date.value,
        finish_date: finish_date.value,
        empresa_id: empresa_id.value,
      });
    };

    return {
      user,
      init_date,
      finish_date,
      handleFilter,
      updateInitDate,
      updateFinishDate,
      updateCompany,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
