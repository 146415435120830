import { isLoading } from "@/shared/base.service";

import { computed, reactive, ref } from "vue";

import {
  helpers,
  minLength,
  minValue,
  required,
  requiredIf,
} from "@vuelidate/validators";

import { useSnackbarStore } from "@/stores/snackbar.store";

import { AccountService } from "@/services";

import type { AccountResponse } from "@/types/responses/account";
import type { NewAccount } from "@/types/requests/newAccount";
import type { ProfileResponse } from "@/types/responses/profile";

import type { CreateAccountRequest } from "@/types/responses/createAccount";

export function useAccounts() {
  const snackbarStore = useSnackbarStore();

  const members = ref();
  const account = ref<AccountResponse>();
  const accounts = ref<ProfileResponse[]>();
  const profile = ref<ProfileResponse>({
    id: 0,
    account_status_id: 0,
    accountable: null,
    accountable_cpf: "",
    user: null,
    email: "",
    id_bmp: null,
    balance: 0,
    status_account: "",
    block_contribution: false,
    accountStatus: {
      id: 0,
      description: "",
      created_at: "",
      updated_at: "",
      empresa_id: 0,
    },
    mother_name: null,
    birth_date: null,
    phone: "",
    zipcode: null,
    street: null,
    street_number: null,
    street_complement: null,
    neighborhood: null,
    state: null,
    city: null,
    empresa: {
      id: 0,
      nome: "",
      documento: "",
      responsavel: "",
      descricao: "",
      url: null,
      logo: "",
      created_at: null,
      updated_at: null,
      empresa_id: 0,
      can_random_pix_key: 0,
    },
    account_type_id: 0,
    player_pix_key: "",
    clerk_pix_key: null,
    pix_plit_message: "",
    pix_active: 0,
    send_kyc: 0,
    qtd_pix: 0,
    statusReason: null,
    hasReturnBalance: false,
  });

  const newTransferFormRules = computed(() => {
    return {
      account_target_id: {
        required: helpers.withMessage(() => `Selecione uma conta`, required),
      },
      amount: {
        required: helpers.withMessage(() => `Informe um valor`, required),
        minLength: minLength(1),
      },
    };
  });

  const newTransferForm = ref({
    account_target_id: "",
    amount: 0,
  });

  const newQrCodeFormRules = computed(() => {
    return {
      account_id: {
        required,
      },
      qr_code: {
        required: helpers.withMessage(() => `Informe um QR Code`, required),
        minLength: minLength(1),
      },
    };
  });

  const newQrCodeForm = ref({
    account_id: 0,
    qr_code: "",
  });

  const newReceiptValueFormRules = computed(() => {
    const isRateZero = newReceiptValueForm.value.rate_zero;

    return {
      account_id: {
        required,
      },
      amount: {
        required: helpers.withMessage(() => `Informe um valor`, required),
        minValue: minValue(1),
      },
      type: {
        required: helpers.withMessage(
          () => `Selecione um tipo`,
          requiredIf(() => isRateZero)
        ),
      },
      login: {
        required: helpers.withMessage(
          () => `Informe um login`,
          requiredIf(() => isRateZero)
        ),
      },
      password: {
        required: helpers.withMessage(
          () => `Informe uma senha`,
          requiredIf(() => isRateZero)
        ),
      },
      description: {
        required: helpers.withMessage(
          () => `Informe uma descrição`,
          requiredIf(() => isRateZero)
        ),
      },
    };
  });

  const newReceiptValueForm = ref({
    account_id: 0,
    amount: "",
    rate_zero: true,
    type: "bonus",
    login: "",
    password: "",
    description: "",
  });

  const createAccountFormRules = computed(() => {
    return {
      accountable_cpf: { required },
      // qrcode: {
      //   required,
      // },
    };
  });

  const createAccountForm = ref<CreateAccountRequest>({
    player_pix_key: "",
    player_pix_key_id: 0,
    accountable_cpf: "",
    pix_key: "",
    qrcode: "",
  });

  const newAccountFormRules = computed(() => {
    // const isRequired = newAccountForm.account_status_id == 7;

    return {
      accountable: {
        // required: requiredIf(() => isRequired),
      },
      // mother_name: {
      //   required: requiredIf(() => isRequired),
      // },
      // father_name: { required: requiredIf(() => isRequired) },
      birth_date: {
        // required: requiredIf(() => isRequired)
      },
      zipcode: {
        // required: requiredIf(() => isRequired)
      },
      street: {
        // required: requiredIf(() => isRequired)
      },
      street_number: {
        // required: requiredIf(() => isRequired)
      },
      // street_complement: { required: requiredIf(() => isRequired) },
      neighborhood: {
        // required: requiredIf(() => isRequired)
      },
      state: {
        // required: requiredIf(() => isRequired)
      },
      city: {
        // required: requiredIf(() => isRequired)
      },
      pictureA: {
        // required: requiredIf(() => isRequired)
      },
      pictureB: {
        // required: requiredIf(() => isRequired)
      },
    };
  });

  const newAccountForm = reactive<NewAccount>({
    id: 0,
    accountable: "",
    accountable_cpf: "",
    account_status_id: 0,
    mother_name: "",
    father_name: "",
    birth_date: "",
    phone: "",
    email: "",
    zipcode: "",
    street: "",
    street_number: "",
    street_complement: "",
    neighborhood: "",
    state: "",
    city: "",
    pictureA: null,
    pictureB: null,
    pix_key: "",
    // player_pix_key_id: 0,
    qrcode: "",
    status: "Aguardando Aporte",
  });

  async function listAccounts(params = null) {
    const accountsService = await AccountService.listAccounts(params);

    members.value = accountsService;

    return members;
  }

  async function listJogador(accountType: number) {
    try {
      const response = await AccountService.ListJogador(accountType);

      if (response) profile.value = response[0];
    } catch (error) {
      console.error(error);
    }
  }

  async function listJogadores(accountType: number) {
    try {
      const response = await AccountService.ListJogador(accountType);

      if (response) accounts.value = response;
    } catch (error) {
      console.error(error);
    }
  }

  async function listMesario(accountType: number) {
    try {
      const response = await AccountService.ListMesario(accountType);

      if (response) profile.value = response[0];
    } catch (error) {
      console.error(error);
    }
  }

  async function getAccountById(id: number) {
    try {
      const response = await AccountService.GetAccountById(id);

      if (response) account.value = response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createAccount(payload: CreateAccountRequest) {
    try {
      const response = await AccountService.CreateAccount(payload);

      if (response.error == true) snackbarStore.showSnackbar(response.message);
      else snackbarStore.showSnackbar("Conta criada com sucesso.");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateAccount(id: number, payload: NewAccount) {
    try {
      const response = await AccountService.UpdateAccount(id, payload);
      snackbarStore.showSnackbar("Conta atualizada com sucesso.");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function aproveBmpCompliceError(accountId: number) {
    try {
      const response = await AccountService.AproveBmpCompliceError(accountId);
      if (response) snackbarStore.showSnackbar("Conta aprovada com sucesso.");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function aproveBmp(accountId: number) {
    try {
      const response = await AccountService.AproveBmp(accountId);
      if (response) snackbarStore.showSnackbar("Pedido reenviado.");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function newTransfer(payload: object) {
    try {
      const response = await AccountService.NewTransfer(payload);
      if (response)
        snackbarStore.showSnackbar("Transfência realizada com sucesso.");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteAccount(id: number) {
    try {
      const response = await AccountService.DeleteAccount(id);
      snackbarStore.showSnackbar("Conta deletada com sucesso.");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateBalance(accountId: number) {
    const accountsService = await AccountService.updateBalance(accountId)
      .then((data) => data)
      .catch((err) => err);
    return accountsService;
  }

  async function sendBrCode(accountId: number) {
    const accountsService = await AccountService.sendBrCode(accountId)
      .then((data) => data)
      .catch((err) => err);
    return accountsService;
  }

  async function createPix(accountId: number) {
    const accountsService = await AccountService.createPix(accountId)
      .then((data) => data)
      .catch((err) => err);
    return accountsService;
  }

  async function inactivateAccount(accountId: number) {
    try {
      const response = await AccountService.InactivateAccount(accountId);

      if (response) snackbarStore.showSnackbar("Conta inativada com sucesso.");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function activateAccount(accountId: number) {
    const accountsService = await AccountService.activateAccount(accountId)
      .then((data) => data)
      .catch((err) => err);
    return accountsService;
  }

  async function returnBalance(accountId: number) {
    const accountsService = await AccountService.returnBalance(accountId)
      .then((data) => data)
      .catch((err) => err);
    return accountsService;
  }

  async function addBalance(data: object) {
    const accountsService = await AccountService.addBalance(data)
      .then((data: object) => data)
      .catch((err) => err);
    return accountsService;
  }

  async function payQrCode(payload: object) {
    try {
      const response = await AccountService.PayQrCode(payload);

      if (response) snackbarStore.showSnackbar("QR Code pago com sucesso.");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function accountReceive(payload: object) {
    try {
      const response = await AccountService.AccountReceive(payload);

      if (response)
        snackbarStore.showSnackbar("Solicitação registrada com sucesso.");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    members,
    account,
    newAccountFormRules,
    newAccountForm,
    listAccounts,
    getAccountById,
    updateAccount,
    aproveBmpCompliceError,
    aproveBmp,
    deleteAccount,
    updateBalance,
    sendBrCode,
    createPix,
    inactivateAccount,
    activateAccount,
    returnBalance,
    addBalance,
    payQrCode,
    listJogador,
    listJogadores,
    listMesario,
    profile,
    accounts,
    newTransfer,
    newTransferFormRules,
    newTransferForm,
    createAccountFormRules,
    createAccountForm,
    newQrCodeFormRules,
    newQrCodeForm,
    newReceiptValueFormRules,
    newReceiptValueForm,
    accountReceive,
    createAccount,
  };
}
