<template>
  <v-dialog v-model="loadingStore.isLoading" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item :title="loadingStore.loadingMessage">
        <template v-slot:append>
          <v-progress-circular
            color="primary"
            indeterminate="disable-shrink"
            size="16"
            width="2"
          ></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useLoadingDialog } from "@/stores/loading.store";

export default defineComponent({
  name: "LoadingDialog",
  setup() {
    const loadingStore = useLoadingDialog();

    return {
      loadingStore,
    };
  },
});
</script>
