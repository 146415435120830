import BaseService from "@/shared/base.service";

import { DomainProps } from "@/types/props/domain.props";

export class WhiteLabelService {
  static async GetWhiteLabelData(domain: string): Promise<DomainProps | null> {
    try {
      // const domainTest = "sosuperbet";

      const response = await BaseService.get<DomainProps>(
        `whitelabel/${domain}`,
        {
          domain: domain,
        }
      );

      // const responseData = {
      //   logo: `https://${domain}.com.br/asset/logo.png`,
      //   favicon: `https://${domainTest}.com.br/asset/logo.png`,
      //   primary_color: response?.primary_color,
      //   secondary_color: response?.secondary_color,
      // } as DomainProps;

      if (response) {
        const envDomain: string = process.env.VUE_APP_API_URL;
        const cleanDomain = envDomain.split("/api/")[0];

        const responseData = {
          nome: response?.nome,
          logo: `${cleanDomain}/asset/logo.png`,
          favicon: `${cleanDomain}/asset/logo.png`,
          primary_color: response?.primary_color,
          secondary_color: response?.secondary_color,
          url: response?.url,
        } as DomainProps;

        return responseData;
      }

      return null;
    } catch (error) {
      console.error("Error in GetWhiteLabelData:", error);
      return null;
    }
  }
}
