import BaseService from "@/shared/base.service";

import type { Table } from "@/types/models/table";
import { NewTable } from "@/types/requests/newTable";
import { NewTableResponse } from "@/types/responses/newTable";

export class TableService {
  static async GetAllTables(name?: string): Promise<Table[] | []> {
    try {
      const params = {
        name,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<Table[]>(`table`, queryParams);

      return response ?? [];
    } catch (error) {
      console.error("Error in GetAllTables:", error);
      return [];
    }
  }

  static async GetTableById(id: number): Promise<NewTable | null> {
    try {
      const response = await BaseService.get<NewTable>(`table/${id}`);
      return response ?? null;
    } catch (error) {
      console.error("Error in GetTableById:", error);
      return null;
    }
  }

  static async VerifyClerk(id: number): Promise<object | null> {
    try {
      const response = await BaseService.get<object>(`table/check/${id}`);
      return response ?? null;
    } catch (error) {
      console.error("Error in GetTableById:", error);
      return null;
    }
  }

  static async CreateTable(
    payload: NewTable
  ): Promise<NewTableResponse | null> {
    try {
      const response = await BaseService.post<NewTableResponse>(
        "table",
        payload
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in CreateTable:", error);
      return null;
    }
  }

  static async UpdateTable(
    id: number,
    payload: NewTable
  ): Promise<NewTableResponse | null> {
    try {
      const response = await BaseService.put<NewTableResponse>(
        `table/${id}`,
        payload
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in UpdateTable:", error);
      return null;
    }
  }
}
