import { defineStore } from "pinia";

export const useSnackbarStore = defineStore({
  id: "snackbar",
  state: () => ({
    isVisible: false,
    message: "Sucesso...",
  }),
  actions: {
    showSnackbar(message: string) {
      this.isVisible = true;
      this.message = message;
    },
  },
});
