import BaseService from "@/shared/base.service";

import type { AccountResponse } from "@/types/responses/account";
import type { CreateAccountRequest } from "@/types/responses/createAccount";
import type { ProfileResponse } from "@/types/responses/profile";
import type { NewAccount } from "@/types/requests/newAccount";

import { AxiosRequestConfig } from "axios";

export class AccountService {
  static async CheckTaxId(taxId: string): Promise<boolean | false> {
    try {
      const response = await BaseService.get<boolean>(
        `check/account/cpf/${taxId}`
      );
      return response ?? false;
    } catch (error) {
      console.error("Error in CheckTaxId:", error);
      return false;
    }
  }

  static async GetBalance(id: number): Promise<ProfileResponse | null> {
    try {
      const response = await BaseService.get<ProfileResponse>(
        `getbalance/${id}`
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetBalance:", error);
      return null;
    }
  }

  static async ListMesario(
    accountType: number
  ): Promise<ProfileResponse[] | null> {
    try {
      const response = await BaseService.get<ProfileResponse[]>(
        `account/listMesario?accountType=${accountType}`
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in ListMesario:", error);
      return null;
    }
  }

  static async ListJogador(
    accountType: number
  ): Promise<ProfileResponse[] | null> {
    try {
      const response = await BaseService.get<ProfileResponse[]>(
        `account/listJogador?accountType=${accountType}`
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in ListJogador:", error);
      return null;
    }
  }

  static async listAccounts(params: object | any = null): Promise<any> {
    try {
      const response = await BaseService.get(`account`, params);
      return response;
    } catch (error) {
      console.error("Error in listAccounts:", error);
      return [];
    }
  }

  static async GetAccountById(id: number): Promise<AccountResponse | null> {
    try {
      const response = await BaseService.get<AccountResponse>(`account/${id}`);
      return response ?? null;
    } catch (error) {
      console.error("Error in GetAccountById:", error);
      return null;
    }
  }

  static async CreateAccount(
    payload: CreateAccountRequest
  ): Promise<any | null> {
    try {
      const response = await BaseService.post<CreateAccountRequest>(
        `account`,
        payload
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in CreateAccount:", error);
      return null;
    }
  }

  static async UpdateAccount(
    id: number,
    payload: NewAccount
  ): Promise<AccountResponse | null> {
    try {
      const formData = AccountService.convertNewAccountToFormData(payload);
      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await BaseService.post<AccountResponse>(
        `account/${id}`,
        formData,
        config
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in UpdateAccount:", error);
      return null;
    }
  }

  static async AproveBmpCompliceError(
    accountId: number
  ): Promise<boolean | null> {
    try {
      const response = await BaseService.post<boolean>(
        `aproveBmpCompliceError/${accountId}`
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in AproveBmpCompliceError:", error);
      return null;
    }
  }

  static async AproveBmp(accountId: number): Promise<boolean | null> {
    try {
      const response = await BaseService.post<boolean>(
        `aproveBmp/${accountId}`
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in AproveBmp:", error);
      return null;
    }
  }

  static async DeleteAccount(id: number): Promise<boolean | false> {
    try {
      const response = await BaseService.delete<boolean>(`account/${id}`);
      return response ?? false;
    } catch (error) {
      console.error("Error in DeleteAccount:", error);
      return false;
    }
  }

  static async NewTransfer(payload: object): Promise<any> {
    try {
      const response = await BaseService.post(`transfer`, payload);
      return response;
    } catch (error) {
      console.error("Error in NewTransfer:", error);
      return [];
    }
  }

  static async updateBalance(accountId: number): Promise<any> {
    try {
      const response = await BaseService.post(
        `account/update_balance/${accountId}`
      );
      return response;
    } catch (error) {
      console.error("Error in updateBalance:", error);
      return [];
    }
  }

  static async sendBrCode(accountId: number): Promise<any> {
    try {
      const response = await BaseService.post(
        `account/send_brcode/${accountId}`
      );
      return response;
    } catch (error) {
      console.error("Error in sendBrCode:", error);
      return [];
    }
  }

  static async InactivateAccount(accountId: number): Promise<any> {
    try {
      const response = await BaseService.post(
        `account/inactive_account/${accountId}`
      );
      return response;
    } catch (error) {
      console.error("Error in InactivateAccount:", error);
      return [];
    }
  }

  static async activateAccount(accountId: number): Promise<any> {
    try {
      const response = await BaseService.post(
        `account/active_account/${accountId}`
      );
      return response;
    } catch (error) {
      console.error("Error in activateAccount:", error);
      return [];
    }
  }

  static async returnBalance(accountId: number): Promise<any> {
    try {
      const response = await BaseService.post(`account/returnBalance`, {
        account_id: accountId,
      });
      return response;
    } catch (error) {
      console.error("Error in returnBalance:", error);
      return [];
    }
  }

  static async createPix(accountId: number): Promise<any> {
    try {
      const response = await BaseService.post(`createPix`, {
        account_id: accountId,
      });
      return response;
    } catch (error) {
      console.error("Error in createPix:", error);
      return [];
    }
  }

  static async addBalance(data: any): Promise<any> {
    try {
      const response = await BaseService.post(`account/addBalance`, data);
      return response;
    } catch (error) {
      console.error("Error in addBalance:", error);
      return [];
    }
  }

  static async PayQrCode(payload: object): Promise<any> {
    try {
      const response = await BaseService.post(
        `account/send_brcode_automatic`,
        payload
      );

      return response;
    } catch (error) {
      console.error("Error in PayQrCode:", error);
      return [];
    }
  }

  static async AccountReceive(payload: object): Promise<any> {
    try {
      const response = await BaseService.post(`accountReceive`, payload);

      return response;
    } catch (error) {
      console.error("Error in AccountReceive:", error);
      return [];
    }
  }

  static convertNewAccountToFormData(payload: NewAccount): FormData {
    const formData = new FormData();

    formData.append("id", payload.id.toString());
    formData.append("accountable", payload.accountable);
    formData.append("accountable_cpf", payload.accountable_cpf);
    formData.append("mother_name", payload.mother_name);
    formData.append("father_name", payload.father_name);
    formData.append("birth_date", payload.birth_date);
    formData.append("phone", payload.phone);
    formData.append("email", payload.email);
    formData.append("zipcode", payload.zipcode);
    formData.append("street", payload.street);
    formData.append("street_number", payload.street_number);
    formData.append("street_complement", payload.street_complement);
    formData.append("neighborhood", payload.neighborhood);
    formData.append("state", payload.state);
    formData.append("city", payload.city);
    formData.append("pix_key", payload.pix_key);
    formData.append("qrcode", payload.qrcode ?? "");
    formData.append("status", payload.status);

    if (payload.pictureA) {
      formData.append("pictureA", payload.pictureA);
    }
    if (payload.pictureB) {
      formData.append("pictureB", payload.pictureB);
    }

    return formData;
  }
}
