import { computed, reactive } from "vue";

import { SettingsService } from "@/services";

import { helpers, minLength, required, sameAs } from "@vuelidate/validators";

import { isLoading } from "@/shared/base.service";

export function useSettings() {
  const updatePasswordFormRules = computed(() => {
    return {
      password: {
        required: helpers.withMessage("A senha é obrigatória.", required),
        minLength: helpers.withMessage(
          "A senha deve ter no mínimo 8 caracteres.",
          minLength(8)
        ),
        containsPasswordRequirement: helpers.withMessage(
          "A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.",
          (value: string) =>
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)
        ),
      },
      password_confirmation: {
        required: helpers.withMessage(
          "A confirmação da senha é obrigatória.",
          required
        ),
        minLength: helpers.withMessage(
          "A confirmação da senha deve ter no mínimo 8 caracteres.",
          minLength(8)
        ),
        sameAsPassword: helpers.withMessage(
          "A confirmação da senha deve corresponder à senha.",
          sameAs(updatePasswordForm.password)
        ),
      },
      // password: {
      //   required,
      //   minLength: minLength(8),
      // },
      // password_confirmation: {
      //   required,
      //   minLength: minLength(8),
      // },
    };
  });

  const updatePasswordForm = reactive({
    password: "",
    password_confirmation: "",
  });

  const updateProfileFormRules = computed(() => {
    return {
      name: {
        required,
      },
      email: {
        required,
      },
    };
  });

  const updateProfileForm = reactive({
    name: "",
    email: "",
  });

  async function checkMaintenanceStatus() {
    try {
      const response = await SettingsService.CheckMaintenanceStatus();

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function setMaintenanceMode(status: "ativo" | "inativo") {
    try {
      const response = await SettingsService.SetMaintenanceMode(status);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updatePassword(payload: any) {
    try {
      const response = await SettingsService.UpdatePassword(payload);

      if (response.error == true) return false;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateProfile(payload: any) {
    try {
      const response = await SettingsService.UpdateProfile(payload);

      if (response.error == true) return false;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    updatePasswordFormRules,
    updatePasswordForm,
    updateProfileFormRules,
    updateProfileForm,
    checkMaintenanceStatus,
    setMaintenanceMode,
    updatePassword,
    updateProfile,
  };
}
