<template>
  <div class="title-header">
    <i :class="icon"></i>
    <span class="title">{{ label }}</span>
    <span class="badge" :style="badgeStyle">{{ counter }}</span>
    <i class="arrow-right ri-arrow-right-s-line"></i>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

import { useWhiteLabelStore } from "@/stores/whiteLabel.store";

export default defineComponent({
  name: "SidebarItem",
  props: {
    counter: {
      type: Number,
      default: 1,
    },
    icon: {
      type: String,
      default: "",
      required: true,
    },
    label: {
      type: String,
      default: "Contas",
      required: true,
    },
  },
  setup() {
    const whiteLabelStore = useWhiteLabelStore();

    const primaryColor = computed(
      () => whiteLabelStore.domain.primary_color || "#3f51b5"
    );
    const secondaryColor = computed(
      () => whiteLabelStore.domain.secondary_color || "#ee368c"
    );

    const badgeStyle = computed(() => ({
      color: primaryColor.value,
    }));

    return {
      badgeStyle,
      secondaryColor,
    };
  },
});
</script>
