import BaseService from "@/shared/base.service";

import type { LoginResponse } from "@/types/responses/login";

export class AuthService {
  static async Login(
    email: string,
    password: string
  ): Promise<LoginResponse | null> {
    try {
      const response = await BaseService.post<LoginResponse>("login", {
        email: email,
        password: password,
      });

      return response ?? null;
    } catch (error) {
      console.error("Error in login:", error);
      return null;
    }
  }

  static async Logout() {
    try {
      const response = await BaseService.post("logout");
      return response;
    } catch (error) {
      console.error("Error in logout:", error);
      return false;
    }
  }
}
