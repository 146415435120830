import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("button", {
    class: "default-btn",
    style: _normalizeStyle(_ctx.buttonStyle),
    type: "submit",
    disabled: _ctx.isLoading
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
          key: 0,
          class: "mr-2",
          indeterminate: "",
          size: "18",
          width: "2",
          color: _ctx.secondaryColor
        }, null, 8, ["color"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
  ], 12, _hoisted_1))
}