<template>
  <v-alert
    class="alert alert-danger d-flex justify-space-between align-items-center"
    closable
    @click:close="closeAlert()"
  >
    {{ error }}
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DangerAlert",
  props: {
    error: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeAlert() {
      this.$emit("close-alert", true);
    },
  },
  mounted() {
    setTimeout(() => {
      this.closeAlert();
    }, 3500);
  },
});
</script>
