import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ri-user-3-line"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, {
    label: $props.label,
    type: $props.type,
    hint: $props.hint,
    "hide-details": !$props.required
  }, {
    default: _withCtx(() => [
      ($props.icon)
        ? (_openBlock(), _createElementBlock("i", _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "type", "hint", "hide-details"]))
}