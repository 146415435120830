import BaseService from "@/shared/base.service";

import type { PlayerPixKey } from "@/types/models/playerPixKey";
import type { PlayerWithdrawResponse } from "@/types/responses/PlayerWithdraw";
import type { NewPlayerWithdraw } from "@/types/requests/newPlayerWithdraw";
import type { PlayerPixKeyResponse } from "@/types/responses/playerPixKey";

export class PlayerService {
  static async ListPlayers(): Promise<any> {
    try {
      const response = await BaseService.get("player");
      return response;
    } catch (error) {
      console.error("Error in listPlayers:", error);
      return [];
    }
  }

  static async GetAllPlayersPixKey(): Promise<PlayerPixKey[] | []> {
    try {
      const response = await BaseService.get<PlayerPixKey[]>(`player_pix_key`);
      return response ?? [];
    } catch (error) {
      console.error("Error in GetAllPlayersPixKey:", error);
      return [];
    }
  }

  static async GetPlayerWithdrawById(
    id: number
  ): Promise<PlayerWithdrawResponse | null> {
    try {
      const response = await BaseService.get<PlayerWithdrawResponse>(
        `player_withdraw/${id}`
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetPlayerWithdrawById:", error);
      return null;
    }
  }

  static async GetPlayerPixKey(): Promise<PlayerPixKeyResponse[] | null> {
    try {
      const response = await BaseService.get<PlayerPixKeyResponse[]>(
        "player_pix_key?type=player"
      );

      return response ?? [];
    } catch (error) {
      console.error("Error in GetPlayerPixKey:", error);
      return [];
    }
  }

  static async CreatePlayerWithdraw(
    payload: NewPlayerWithdraw
  ): Promise<PlayerWithdrawResponse | null> {
    try {
      const response = await BaseService.post<PlayerWithdrawResponse>(
        `player_withdraw`,
        payload
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in CreatePlayerWithdraw:", error);
      return null;
    }
  }

  static async CreatePlayerPixKey(id: number): Promise<string | null> {
    try {
      const response = await BaseService.post<string>(
        `player_pix_key/create_pix/${id}`
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in CreatePlayerPixKey:", error);
      return null;
    }
  }
}
