import { ref } from "vue";

import { DashboardService } from "@/services";

import { isLoading } from "@/shared/base.service";

export function useDashboard() {
  const pocketBalance = ref(0);
  const accountsBalance = ref({
    accounts: 0,
    players: 0,
  });
  const counterAccount = ref({
    counterActive: 0,
    counterDay: 0,
    counterTotal: 0,
  });
  const accountFinancials = ref({
    counterPaid: 0,
    counterPending: 0,
    counterTotal: 0,
  });

  const filters = ref({
    init_date: "2024-05-14",
    finish_date: "2024-05-29",
    empresa_id: null,
  });

  async function getPocketBalance() {
    try {
      const response = await DashboardService.GetPocketBalance();

      if (response.length != 0) pocketBalance.value = parseFloat(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function getAccountBalance() {
    try {
      const response = await DashboardService.GetAccountBalance();

      if (response)
        accountsBalance.value = {
          accounts: response?.accounts,
          players: response?.players,
        };
    } catch (error) {
      console.error(error);
    }
  }

  async function getCounterAccounts(
    init_date?: string,
    finish_date?: string,
    empresa_id?: number
  ) {
    try {
      const response = await DashboardService.GetCounterAccounts(
        init_date,
        finish_date,
        empresa_id
      );

      if (response)
        counterAccount.value = {
          counterActive: response.counterActive,
          counterDay: response.counterDay,
          counterTotal: response.counterTotal,
        };
    } catch (error) {
      console.error(error);
    }
  }

  async function getAcountFinancials(
    init_date?: string,
    finish_date?: string,
    empresa_id?: number
  ) {
    try {
      const response = await DashboardService.GetAccountFinancials(
        init_date,
        finish_date,
        empresa_id
      );

      if (response)
        accountFinancials.value = {
          counterPaid: response.counterPaid,
          counterPending: response.counterPending,
          counterTotal: response.counterTotal,
        };
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    pocketBalance,
    accountsBalance,
    counterAccount,
    accountFinancials,
    filters,
    getPocketBalance,
    getAccountBalance,
    getCounterAccounts,
    getAcountFinancials,
  };
}
