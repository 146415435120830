import BaseService from "@/shared/base.service";

export class SettingsService {
  static async CheckMaintenanceStatus(): Promise<boolean | false> {
    try {
      const response = await BaseService.get<boolean>(`is_manutencao`);

      return response ?? false;
    } catch (error) {
      console.error("Error in CheckMaintenanceStatus:", error);
      return false;
    }
  }

  static async SetMaintenanceMode(
    status: "ativo" | "inativo"
  ): Promise<boolean | false> {
    try {
      const response = await BaseService.get<boolean>(
        `change_manutencao_moode/${status}`
      );

      return response ?? false;
    } catch (error) {
      console.error("Error in SetMaintenanceMode:", error);
      return false;
    }
  }

  static async UpdatePassword(payload: object): Promise<any | object> {
    try {
      const response = await BaseService.patch<any>(
        `settings/password`,
        payload
      );

      return response ?? { error: false };
    } catch (error) {
      console.error("Error in UpdatePassword:", error);
      return { error: true };
    }
  }

  static async UpdateProfile(payload: object): Promise<any | object> {
    try {
      const response = await BaseService.patch<any>(
        `settings/profile`,
        payload
      );

      return response ?? { error: false };
    } catch (error) {
      console.error("Error in UpdateProfile:", error);
      return { error: true };
    }
  }
}
