import { computed, reactive, Ref, ref } from "vue";

import {
  minLength,
  required,
  email,
  minValue,
  requiredIf,
  maxLength,
  helpers,
  sameAs,
} from "@vuelidate/validators";

import { useAuthStore } from "@/stores/auth.store";

import { UserService } from "@/services";

import { isLoading } from "@/shared/base.service";

import type { User } from "@/types/models/user";
import type { NewJogador } from "@/types/requests/newJogador";
import type { NewMesario } from "@/types/requests/newMesario";
import type { NewProdutor } from "@/types/requests/newProdutor";

export function useUser() {
  const authStore = useAuthStore();

  const loggedUser: Ref = ref(authStore.getUser);

  const user = ref<User>();
  const users = ref<User[]>([]);

  // const newJogadorFormRules = computed(() => {
  //   const isAdmin = loggedUser.value.type === "admin";

  //   return {
  //     cpf: {
  //       required,
  //       minLength: minLength(14),
  //     },
  //     empresa_id: {
  //       required: requiredIf(() => isAdmin),
  //       minValue: () => (isAdmin ? minValue(1) : minValue(0)),
  //     },
  //     email: {
  //       required,
  //       email,
  //     },
  //     account: {
  //       accountable_cpf: {
  //         required,
  //         minLength: minLength(14),
  //       },
  //       phone: {
  //         required,
  //         minLength: minLength(15),
  //         maxLength: maxLength(16),
  //       },
  //       email: {
  //         required,
  //         email,
  //       },
  //       account_type: {
  //         required,
  //       },
  //     },
  //     type: {
  //       required,
  //     },
  //     password: {
  //       required,
  //       minLength: minLength(8),
  //     },
  //     password_confirmation: {
  //       required,
  //       minLength: minLength(8),
  //     },
  //   };
  // });

  const newJogadorFormRules = computed(() => {
    const isAdmin = loggedUser.value.type === "admin";

    return {
      cpf: {
        required,
        minLength: minLength(14),
      },
      name: {
        // required,
        // minLength: minLength(4),
      },
      email: {
        required,
        email,
      },
      // type: {
      //   // required,
      // },
      password: {
        required: helpers.withMessage("A senha é obrigatória.", required),
        minLength: helpers.withMessage(
          "A senha deve ter no mínimo 8 caracteres.",
          minLength(8)
        ),
        containsPasswordRequirement: helpers.withMessage(
          "A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.",
          (value: string) =>
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)
        ),
      },
      password_confirmation: {
        required: helpers.withMessage(
          "A confirmação da senha é obrigatória.",
          required
        ),
        minLength: helpers.withMessage(
          "A confirmação da senha deve ter no mínimo 8 caracteres.",
          minLength(8)
        ),
        sameAsPassword: helpers.withMessage(
          "A confirmação da senha deve corresponder à senha.",
          sameAs(newJogadorForm.password)
        ),
      },
      // password: {
      //   required,
      //   minLength: minLength(8),
      // },
      // password_confirmation: {
      //   required,
      //   minLength: minLength(8),
      // },
      accountable: {
        // required,
      },
      board_id: {
        required: requiredIf(() => isAdmin),
        minValue: () => (isAdmin ? minValue(1) : minValue(0)),
      },
      account: {
        accountable_cpf: {
          required,
          minLength: minLength(14),
        },
        accountable: {
          // required,
        },
        mother_name: {
          // required,
          // minLength: minLength(4),
        },
        birth_date: {
          // required,
        },
        father_name: {
          // required,
          // minLength: minLength(4),
        },
        phone: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(15),
        },
        email: {
          required,
          email,
        },
        account_type: {
          // required,
        },
        zipcode: {
          // required,
          // minLength: minLength(9),
        },
        street: {
          // required,
          // minLength: minLength(2),
        },
        street_number: {
          // required,
          // minValue: minValue(1),
        },
        street_complement: {
          // required,
          // minLength: minLength(1),
        },
        neighborhood: {
          // required,
          // minLength: minLength(1),
        },
        state: {
          // required,
          // minLength: minLength(2),
        },
        city: {
          // required,
          // minLength: minLength(1),
        },
        pictureA: {
          required,
        },
        pictureB: {
          required,
        },
      },
    };
  });

  const newJogadorForm = reactive<NewJogador>({
    cpf: "",
    name: "",
    email: "",
    // type: "jogador",
    password: "",
    password_confirmation: "",
    accountable: "",
    // empresa_id: 0,
    board_id: 0,
    account: {
      accountable_cpf: "",
      accountable: "",
      mother_name: "",
      birth_date: "",
      father_name: "",
      phone: "",
      email: "",
      account_type: "JOGADOR",
      zipcode: "",
      street: "",
      street_number: "",
      street_complement: "",
      neighborhood: "",
      state: "",
      city: "",
      pictureA: null,
      pictureB: null,
    },
  });

  const updateJogadorForm = reactive({
    automatic_payment_amount: "",
    limit_aporte: "",
    white_list_qr_code: "",
    import_account: "",
    limit_rate_zero_bonus: "",
    password: "",
    user_id: 0,
  });

  // const newJogadorForm = reactive<NewJogador>({
  //   cpf: "",
  //   email: "",
  //   empresa_id: 0,
  //   account: {
  //     accountable_cpf: "",
  //     phone: "",
  //     email: "",
  //     account_type: "jogador",
  //   },
  //   type: "jogador",
  //   password: "",
  //   password_confirmation: "",
  // });

  // const newMesarioFormRules = computed(() => {
  //   const isAdmin = loggedUser.value.type === "admin";

  //   return {
  //     cpf: {
  //       required,
  //       minLength: minLength(14),
  //     },
  //     empresa_id: {
  //       required: requiredIf(() => isAdmin),
  //       minValue: () => (isAdmin ? minValue(1) : minValue(0)),
  //     },
  //     email: {
  //       required,
  //       email,
  //     },
  //     account: {
  //       accountable_cpf: {
  //         required,
  //         minLength: minLength(14),
  //       },
  //       phone: {
  //         required,
  //         minLength: minLength(15),
  //         maxLength: maxLength(16),
  //       },
  //       email: {
  //         required,
  //         email,
  //       },
  //       account_type: {
  //         required,
  //       },
  //     },
  //     type: {
  //       required,
  //     },
  //     password: {
  //       required,
  //       minLength: minLength(8),
  //     },
  //     password_confirmation: {
  //       required,
  //       minLength: minLength(8),
  //     },
  //   };
  // });

  const newMesarioFormRules = computed(() => {
    // const isAdmin = loggedUser.value.type === "admin";

    return {
      cpf: {
        required,
        minLength: minLength(14),
      },
      name: {
        // required,
        // minLength: minLength(4),
      },
      email: {
        required,
        email,
      },
      // type: {
      //   // required,
      // },
      password: {
        required: helpers.withMessage("A senha é obrigatória.", required),
        minLength: helpers.withMessage(
          "A senha deve ter no mínimo 8 caracteres.",
          minLength(8)
        ),
        containsPasswordRequirement: helpers.withMessage(
          "A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.",
          (value: string) =>
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)
        ),
      },
      password_confirmation: {
        required: helpers.withMessage(
          "A confirmação da senha é obrigatória.",
          required
        ),
        minLength: helpers.withMessage(
          "A confirmação da senha deve ter no mínimo 8 caracteres.",
          minLength(8)
        ),
        sameAsPassword: helpers.withMessage(
          "A confirmação da senha deve corresponder à senha.",
          sameAs(newMesarioForm.password)
        ),
      },
      // password: {
      //   required,
      //   minLength: minLength(8),
      // },
      // password_confirmation: {
      //   required,
      //   minLength: minLength(8),
      // },
      accountable: {
        // required,
      },
      empresa_id: {
        // required: requiredIf(() => isAdmin),
        // minValue: () => (isAdmin ? minValue(1) : minValue(0)),
      },
      account: {
        accountable_cpf: {
          required,
          minLength: minLength(14),
        },
        accountable: {
          // required,
        },
        mother_name: {
          // required,
          // minLength: minLength(4),
        },
        birth_date: {
          // required,
        },
        father_name: {
          // required,
          // minLength: minLength(4),
        },
        phone: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(15),
        },
        email: {
          required,
          email,
        },
        account_type: {
          // required,
        },
        zipcode: {
          // required,
          // minLength: minLength(9),
        },
        street: {
          // required,
          // minLength: minLength(2),
        },
        street_number: {
          // required,
          // minValue: minValue(1),
        },
        street_complement: {
          // required,
          // minLength: minLength(1),
        },
        neighborhood: {
          // required,
          // minLength: minLength(1),
        },
        state: {
          // required,
          // minLength: minLength(2),
        },
        city: {
          // required,
          // minLength: minLength(1),
        },
        pictureA: {
          required,
        },
        pictureB: {
          required,
        },
      },
    };
  });

  const newMesarioForm = reactive<NewMesario>({
    cpf: "",
    name: "",
    email: "",
    // type: "mesario",
    password: "",
    password_confirmation: "",
    accountable: "",
    empresa_id: 0,
    account: {
      accountable_cpf: "",
      accountable: "",
      mother_name: "",
      birth_date: "",
      father_name: "",
      phone: "",
      email: "",
      account_type: "MESARIO",
      zipcode: "",
      street: "",
      street_number: "",
      street_complement: "",
      neighborhood: "",
      state: "",
      city: "",
      pictureA: null,
      pictureB: null,
    },
  });

  const updateMesarioForm = reactive({
    automatic_payment_amount: "",
    max_bet: "",
    can_player: 0,
    password: "",
    user_id: 0,
  });

  // const newMesarioForm = reactive<NewMesario>({
  //   cpf: "",
  //   email: "",
  //   empresa_id: 0,
  //   account: {
  //     accountable_cpf: "",
  //     phone: "",
  //     email: "",
  //     account_type: "mesario",
  //   },
  //   type: "mesario",
  //   password: "",
  //   password_confirmation: "",
  // });

  const newProdutorFormRules = computed(() => {
    return {
      name: {
        required,
        minLength: minLength(10),
      },
      email: {
        required,
        email,
      },
      table_id: {
        required,
        minValue: minValue(1),
      },
      empresa_id: {
        required,
        minValue: minValue(1),
      },
      type: {
        required,
      },
      password: {
        required: helpers.withMessage("A senha é obrigatória.", required),
        minLength: helpers.withMessage(
          "A senha deve ter no mínimo 8 caracteres.",
          minLength(8)
        ),
        containsPasswordRequirement: helpers.withMessage(
          "A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.",
          (value: string) =>
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)
        ),
      },
      password_confirmation: {
        required: helpers.withMessage(
          "A confirmação da senha é obrigatória.",
          required
        ),
        minLength: helpers.withMessage(
          "A confirmação da senha deve ter no mínimo 8 caracteres.",
          minLength(8)
        ),
        sameAsPassword: helpers.withMessage(
          "A confirmação da senha deve corresponder à senha.",
          sameAs(newProdutorForm.password)
        ),
      },
      // password: {
      //   required,
      //   minLength: minLength(8),
      // },
      // password_confirmation: {
      //   required,
      //   minLength: minLength(8),
      // },
    };
  });

  const newProdutorForm = reactive<NewProdutor>({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    type: "produtor",
    table_id: 0,
    empresa_id: 0,
  });

  async function getAllUsers(
    role: "admin" | "mesario" | "produtor",
    type: "jogador" | "mesario" | "produtor"
  ) {
    try {
      const response = await UserService.GetAllUsers(type);
      users.value = response;

      // Caso buscar por 'jogador' e logado como 'produtor',
      // remove os retornos de mesarios.
      if (type == "jogador" && role == "produtor")
        users.value = users.value.filter((user) => user.type == "jogador");
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserById(id: number) {
    try {
      const response = await UserService.GetUserById(id);
      if (response) user.value = response;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createJogador(payload: NewJogador) {
    try {
      const response = await UserService.CreateJogador(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function CreateJogadorWithKYC(payload: NewJogador) {
    try {
      const response = await UserService.CreateJogadorWithKYC(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateJogador(id: number, payload: object) {
    try {
      const response = await UserService.UpdateJogador(id, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createMesario(payload: NewMesario) {
    try {
      const response = await UserService.CreateMesario(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateMesario(id: number, payload: object) {
    try {
      const response = await UserService.UpdateMesario(id, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createMesarioWithKYC(payload: NewMesario) {
    try {
      const response = await UserService.CreateMesarioWithKYC(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createProdutor(payload: NewProdutor) {
    try {
      const response = await UserService.CreateProdutor(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function activeUser(id: number) {
    try {
      const response = await UserService.ActiveUser(id);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function inactiveUser(id: number) {
    try {
      const response = await UserService.InactiveUser(id);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    loggedUser,
    user,
    users,
    newJogadorFormRules,
    newJogadorForm,
    updateJogadorForm,
    newMesarioFormRules,
    newMesarioForm,
    updateMesarioForm,
    newProdutorFormRules,
    newProdutorForm,
    getAllUsers,
    getUserById,
    createJogador,
    CreateJogadorWithKYC,
    updateJogador,
    createMesario,
    createMesarioWithKYC,
    updateMesario,
    createProdutor,
    activeUser,
    inactiveUser,
  };
}
