import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { jwtDecode } from "jwt-decode";

import { useAuthStore } from "@/stores/auth.store";

import LoginPage from "@/pages/Auth/LoginPage.vue";
import AccountsPage from "@/pages/Accounts/AccountsPage.vue";

import DashboardPage from "@/pages/Dashboard/DashboardPage.vue";

import ErrorPage from "@/pages/ErrorPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/autenticar/login",
    name: "LoginPage",
    component: LoginPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/configuracoes/conta",
    name: "SettingsAccountPage",
    component: () => import("@/pages/Settings/AccountPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["jogador", "mesario", "produtor", "admin"],
    },
  },
  {
    path: "/configuracoes/perfil",
    name: "SettingsProfilePage",
    component: () => import("@/pages/Settings/ProfilePage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["jogador", "mesario", "produtor", "admin"],
    },
  },
  {
    path: "/configuracoes/senha",
    name: "SettingsPasswordPage",
    component: () => import("@/pages/Settings/PasswordPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["jogador", "mesario", "produtor", "admin"],
    },
  },
  {
    path: "/configuracoes/pix",
    name: "SettingsPixPage",
    component: () => import("@/pages/Settings/PixPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["jogador", "mesario"],
    },
  },
  {
    path: "/",
    name: "DashboardPage",
    component: DashboardPage,
    meta: {
      requiresAuth: true,
      roles: ["jogador", "mesario", "produtor", "admin"],
    },
  },
  {
    path: "/financeiro/extrato",
    name: "ExtractsPage",
    component: () => import("@/pages/Financial/Extracts/ExtractsPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["jogador", "mesario", "admin"],
    },
  },
  {
    path: "/financeiro/extrato-ip",
    name: "IpExtractsPage",
    component: () => import("@/pages/Financial/IpExtracts/IpExtractsPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },
  {
    path: "/financeiro/taxa-zero",
    name: "RateZeroPage",
    component: () => import("@/pages/Financial/RateZero/RateZeroPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },
  {
    path: "/financeiro/taxas",
    name: "FeePage",
    component: () => import("@/pages/Financial/Fee/FeePage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },
  {
    path: "/financeiro/lista",
    name: "ListPage",
    component: () => import("@/pages/Financial/List/ListPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["mesario", "produtor", "admin"],
    },
  },
  {
    path: "/financeiro/pagamentos",
    name: "PaymentPage",
    component: () => import("@/pages/Financial/Payment/PaymentPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["mesario", "produtor", "admin"],
    },
  },
  // {
  //   path: "/financeiro/pagamentos/detalhes",
  //   name: "PaymentDetailsPage",
  //   component: () => import("@/pages/Financial/Payment/PaymentDetailsPage.vue"),
  //   props: { ids: Array },
  //   meta: {
  //     requiresAuth: true,
  //     roles: ["mesario", "produtor", "admin"],
  //   },
  // },
  {
    path: "/financeiro/lista-saque",
    name: "WithdrawListPage",
    component: () => import("@/pages/Financial/Withdraw/WithdrawListPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["jogador", "mesario", "admin"],
    },
  },
  {
    path: "/financeiro/transferencia",
    name: "TransferPage",
    component: () => import("@/pages/Financial/Transfer/TransferPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["jogador"],
    },
  },
  {
    path: "/financeiro/novo-saque",
    name: "NewWithdraw",
    component: () => import("@/pages/Financial/Withdraw/NewWithdraw.vue"),
    meta: {
      requiresAuth: true,
      roles: ["jogador", "mesario", "admin"],
    },
  },
  {
    path: "/usuario/listagem/:type",
    name: "UsersListPage",
    component: () => import("@/pages/User/IndexPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/usuario/saque/:id",
    name: "WithdrawPage",
    component: () => import("@/pages/User/Jogador/WithdrawPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["mesario", "admin"],
    },
  },
  // {
  //   path: "/usuario/novo-jogador",
  //   name: "NewJogadorPage",
  //   component: () => import("@/pages/User/Jogador/NewJogadorPageOld.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     roles: ["mesario", "admin"],
  //   },
  // },
  {
    path: "/usuario/novo-jogador",
    name: "NewJogadorPage",
    component: () => import("@/pages/User/Jogador/NewJogadorPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["mesario", "admin"],
    },
  },
  {
    path: "/usuario/novo-mesario",
    name: "NewMesarioPage",
    component: () => import("@/pages/User/Mesario/NewMesarioPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["produtor", "admin"],
    },
  },
  {
    path: "/usuario/novo-produtor",
    name: "NewProdutorPage",
    component: () => import("@/pages/User/Produtor/NewProdutorPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },
  {
    path: "/ferramentas/mesas",
    name: "TablePage",
    component: () => import("@/pages/Tools/Table/TablePage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },
  {
    path: "/ferramentas/mesas/nova-mesa",
    name: "NewTablePage",
    component: () => import("@/pages/Tools/Table/NewTablePage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },
  {
    path: "/ferramentas/mesas/editar-mesa/:id",
    name: "EditTablePage",
    component: () => import("@/pages/Tools/Table/NewTablePage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },
  {
    path: "/contas",
    name: "AccountsPage",
    component: AccountsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nova-conta",
    name: "NewAccountsPage",
    component: () => import("@/pages/Accounts/NewAccountsPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["jogador"],
    },
  },
  {
    path: "/contas/:id",
    name: "AccountsPageDetails",
    component: () => import("@/pages/Accounts/AccountsPageDetails.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financial/movements/adjustments",
    name: "FinancialAdjustments",
    component: () =>
      import("@/pages/Financial/Movements/FinancialAdjustmentsPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },

  {
    path: "/financial/movements/extract",
    name: "FinancialExtract",
    component: () => import("@/pages/Financial/Movements/ExtractPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },

  {
    path: "/financial/movements/donepayments",
    name: "DonePayments",
    component: () => import("@/pages/Financial/Movements/DonePaymentsPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },

  {
    path: "/financial/movements/paid_bills",
    name: "PaidBills",
    component: () => import("@/pages/Financial/Movements/PaidBillsPage.vue"),
    meta: {
      requiresAuth: true,
      roles: ["admin"],
    },
  },
  {
    path: "/maintenance",
    name: "MaintenancePage",
    component: () => import("@/pages/MaintenancePage.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  // Definir roles dinamicamente com base no parâmetro `type`
  if (to.name === "UsersListPage" && to.params.type) {
    const type = to.params.type as string;
    switch (type) {
      case "jogador":
        to.meta.roles = ["mesario", "produtor", "admin"];
        break;
      case "mesario":
        to.meta.roles = ["produtor", "admin"];
        break;
      case "produtor":
        to.meta.roles = ["admin"];
        break;
      default:
        to.meta.roles = [];
    }
  }

  if (to.meta.requiresAuth) {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token && user) {
      const tokenDecoded = jwtDecode(token, {
        header: false,
      });

      const userDecoded = JSON.parse(user);

      const isExpired =
        !!tokenDecoded.exp && Date.now() > tokenDecoded.exp * 1000;

      if (isExpired) {
        useAuthStore().setLogout();

        router.push({ path: "/autenticar/login" }).then(() => {
          router.go(0);
        });
      }

      const userRole = userDecoded.type;

      if (Array.isArray(to.meta.roles) && !to.meta.roles.includes(userRole)) {
        router.go(-1);
      } else {
        // User is authenticated, proceed to the route
        next();
      }
    } else {
      // User is not authenticated, redirect to login

      router.push({ path: "/autenticar/login" }).then(() => {
        router.go(0);
      });

      //document.location.href = "/autenticar/login"
      next();
    }
  } else {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (to.name === "LoginPage" && token && user) router.push({ path: "/" });

    // Non-protected route, allow access
    next();
  }
});

export default router;
