<template>
  <button class="default-btn" :style="buttonStyle" type="submit" :disabled="isLoading">
    <v-progress-circular
      v-if="isLoading"
      class="mr-2"
      indeterminate
      size="18"
      width="2"
      :color="secondaryColor"
    >
    </v-progress-circular>

    <span>{{ label }}</span>
  </button>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

import { useWhiteLabelStore } from "@/stores/whiteLabel.store";

export default defineComponent({
  name: "PrimaryButton",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "submit",
    },
    label: {
      type: String,
      default: "Entrar",
      required: true,
    },
  },
  setup() {
    const whiteLabelStore = useWhiteLabelStore();

    const primaryColor = computed(
      () => whiteLabelStore.domain.primary_color || "#3f51b5"
    );
    const secondaryColor = computed(
      () => whiteLabelStore.domain.secondary_color || "#ffffff"
    );

    const buttonStyle = computed(() => ({
      backgroundColor: primaryColor.value,
      //   color: secondaryColor.value,
    }));

    return {
      buttonStyle,
      secondaryColor,
    };
  },
});
</script>
