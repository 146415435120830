import BaseService from "@/shared/base.service";

import type { AccountBalanceResponse } from "@/types/responses/accountBalance";
import type { CounterAccountsResponse } from "@/types/responses/counterAccounts";
import type { AccountFinancialsResponse } from "@/types/responses/accountFinancials";

export class DashboardService {
  static async GetPocketBalance(): Promise<string | ""> {
    try {
      const response = await BaseService.get<string>("bolsao");
      return response ?? "";
    } catch (error) {
      console.error("Error in getPocketBalance:", error);
      return "Error in getPocketBalance";
    }
  }

  static async GetAccountBalance(): Promise<AccountBalanceResponse | null> {
    try {
      const response = await BaseService.get<AccountBalanceResponse>(
        "saldo/accounts"
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in getPocketBalance:", error);
      return null;
    }
  }

  static async GetCounterAccounts(
    init_date?: string,
    finish_date?: string,
    empresa_id?: number
  ): Promise<CounterAccountsResponse | null> {
    try {
      const response = await BaseService.get<CounterAccountsResponse>(
        "counter_accounts",
        {
          init_date,
          finish_date,
          empresa_id,
        }
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in GetCounterAccounts:", error);
      return null;
    }
  }

  static async GetAccountFinancials(
    init_date?: string,
    finish_date?: string,
    empresa_id?: number
  ): Promise<AccountFinancialsResponse | null> {
    try {
      const response = await BaseService.get<AccountFinancialsResponse>(
        "counter_financials",
        {
          init_date,
          finish_date,
          empresa_id,
        }
      );
      return response ?? null;
    } catch (error) {
      console.error("Error in GetAccountFinancials:", error);
      return null;
    }
  }
}
