import { useWhiteLabelStore } from "@/stores/whiteLabel.store";

export function DomainUtils() {
  const whiteLabelStore = useWhiteLabelStore();

  const getDomainAndSubdomain = () => {
    const envDomain = process.env.VUE_APP_API_URL;
    const cleanDomain = envDomain.split("/api/")[0];

    const url = new URL(cleanDomain);
    const hostname = url.hostname;

    const parts = hostname.split(".");

    let domain, subdomain;

    if (parts.length > 2) {
      // Remove ".com.br" ou outra combinação de domínio de nível superior
      domain = parts.slice(-3, -2).join(".");
      subdomain = parts.slice(0, -3).join(".");
    } else {
      domain = parts[0]; // Só o nome da empresa
      subdomain = "";
    }

    return { domain, subdomain };
  };

  const setWhiteLabelProps = () => {
    if (whiteLabelStore.domain) {
      whiteLabelStore.setTitle(whiteLabelStore.domain.nome);
      whiteLabelStore.setLogoImage(whiteLabelStore.domain.logo);
      whiteLabelStore.setFavicon(whiteLabelStore.domain.favicon);
      whiteLabelStore.setTheme(
        whiteLabelStore.domain.primary_color,
        whiteLabelStore.domain.secondary_color
      );
    } else {
      console.error("Domínio não mapeado ou desconhecido.");
    }
  };

  return {
    getDomainAndSubdomain,
    setWhiteLabelProps,
  };
}
