<template>
  <v-textarea rows="1" auto-grow :label="label" :hide-details="!required">
    <i class="ri-user-3-line" v-if="icon"></i>
  </v-textarea>
</template>

<script lang="ts">
export default {
  name: "TextareaInput",
  props: {
    label: {
      type: String,
      default: "Escreva",
    },

    required: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
